import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Select,
  Input,
  InputNumber,
  message,
  Checkbox,
  Row,
  Col,
  Divider,
} from 'antd';
import { useTranslation } from 'react-i18next';
import OverflowRangesConfig from '@components/ui/OverflowRangesConfig/OverflowRangesConfig';
import DifPerOperatorConfig from '@components/ui/DifPerOperatorConfig/DifPerOperatorConfig';
import ModalOfSteps from '@src/components/ModalOfSteps/ModalOfSteps';
import apiMiddleware from '@src/services/apiMiddleware';
import LabelModel from '@src/components/LabelModel/LabelModel';
import SelectCustomers from '@src/components/SelectCustomers/index';
import useSwr from '@src/hooks/useSwr';

const { Option } = Select;

const NewContractModal = ({ visible, onClose, mutateNewContract }) => {
  const { t } = useTranslation(['contracts', 'attributes', 'placeholders']);

  const [customerId, setCustomerId] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [sharingType, setSharingType] = useState();
  const [payPerUseFlag, setPayPerUseFlag] = useState();
  const [selectedPlanType, setSelectedPlanType] = useState();
  const [operatorDiffChargeFlag, setOperatorDiffChargeFlag] = useState();
  const [planDesc, setPlanDesc] = useState();
  const [price, setPrice] = useState(0);
  const [activationPrice, setActivationPrice] = useState(0);
  const [franchise, setFranchiseByTerminal] = useState(0);
  const [sharedFranchise, setSharedFranchise] = useState(0);
  const [mbExcValue, setMbExcValue] = useState(0);
  const [automaticBlocking, setAutomaticBlocking] = useState(false);
  const [defaultOverflowPricing, setDefaultOverflowPricing] = useState([]);
  const [defaultOperatorPricing, setDefaultOperatorPricing] = useState([]);
  const [loyaltyType, setLoyaltyType] = useState(0);
  const [loyaltyTime, setLoyaltyTime] = useState();
  const [penalty, setPenalty] = useState(0);
  const [externalId, setExternalId] = useState();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [motOperator, setMotOperator] = useState();
  const [activeRoaming, setActiveRoaming] = useState(false);
  const [planType, setPlanType] = useState();
  const [planExpirationMonths, setPlanExpirationMonths] = useState();
  const [automaticRenewal, setAutomaticRenewal] = useState(false);
  const [iscaDurationInDays, setIscaDurationInDays] = useState();
  const [iscaMaxRenewal, setIscaMaxRenewal] = useState(2);

  const createContract = useCallback(
    payload =>
      apiMiddleware.post('/service-proxy/broker/virtual-plan', payload),
    [],
  );

  const { data: operatorsData } = useSwr('/service-proxy/mot-operators');

  const operatorsOptions = useMemo(
    () =>
      operatorsData?.map(({ name }) => ({
        label: name,
        value: name,
      })),
    [operatorsData],
  );

  useEffect(() => {
    setModalVisible(visible);
  }, [visible]);

  const cleanStateAndClose = useCallback(() => {
    setCustomerId(undefined);
    setSharingType(undefined);
    setPayPerUseFlag(undefined);
    setSelectedPlanType(undefined);
    setOperatorDiffChargeFlag(undefined);
    setPlanDesc(undefined);
    setPrice(0);
    setActivationPrice(0);
    setFranchiseByTerminal(0);
    setSharedFranchise(0);
    setMbExcValue(0);
    setAutomaticBlocking(false);
    setDefaultOverflowPricing([]);
    setDefaultOperatorPricing([]);
    setLoyaltyType(0);
    setLoyaltyTime();
    setPenalty(0);
    setExternalId(undefined);
    setMotOperator(undefined);
    setActiveRoaming(false);
    setPlanType(undefined);
    setPlanExpirationMonths(undefined);
    setAutomaticRenewal(false);
    setIscaDurationInDays(undefined);
    setIscaMaxRenewal(2);
    onClose();
  }, [onClose]);

  const handleSaveContract = useCallback(async () => {
    const defaultOverflowPricingFormatted = defaultOverflowPricing.map(
      item => ({
        chargingFinalBytes: item.nuFaixaFinalBytes,
        chargingInitialBytes: item.nuFaixaInicialBytes,
        chargingMinimalBytes: item.nuCobrancaMinimaBytes,
        chargingIncrementalBytes: item.nuCobrancaIncrementalBytes,
        megabyteChargeValue: item.nuValorNegociadoMbyte,
        operatorId: item.idOperadora,
      }),
    );

    const defaultOperatorPricingFormatted = defaultOperatorPricing.map(
      item => ({
        operatorId: item.idOperadora,
        chargingValue: item.nuValorLiquido.toString(),
      }),
    );

    const payload = {
      customerId,
      activationPrice,
      defaultOperatorPricing: defaultOperatorPricingFormatted,
      defaultOverflowPricing: defaultOverflowPricingFormatted,
      franchise,
      megabyteExcPrice: mbExcValue,
      operatorDiffChargeFlag,
      payPerUseFlag,
      planDesc,
      price,
      sharedFranchise,
      sharingType,
      automaticBlocking,
      loyaltyType,
      loyaltyTime,
      penalty,
      externalId,
      motOperator,
      roaming: activeRoaming,
      planType,
      iscaDurationInDays,
      iscaMaxRenewal,
      planExpirationMonths,
      automaticRenewal,
    };

    setConfirmLoading(true);
    try {
      await createContract(payload);
      mutateNewContract(payload);
      message.success(t('msgs.success'));
      cleanStateAndClose();
    } catch {
      message.error(t('msgs.error'));
      cleanStateAndClose();
    }
    setConfirmLoading(false);
  }, [
    defaultOverflowPricing,
    defaultOperatorPricing,
    customerId,
    activationPrice,
    franchise,
    mbExcValue,
    operatorDiffChargeFlag,
    payPerUseFlag,
    planDesc,
    price,
    sharedFranchise,
    sharingType,
    automaticBlocking,
    loyaltyType,
    loyaltyTime,
    penalty,
    externalId,
    motOperator,
    activeRoaming,
    createContract,
    mutateNewContract,
    t,
    cleanStateAndClose,
    planExpirationMonths,
    planType,
    automaticRenewal,
    iscaDurationInDays,
    iscaMaxRenewal,
  ]);

  const handleSelectContractTypeChange = useCallback(value => {
    setSelectedPlanType(value);
    if (value === 4) {
      setSharingType(1);
      setPayPerUseFlag('S');
      setOperatorDiffChargeFlag('N');
    } else {
      setSharingType(value);
      setPayPerUseFlag('N');
    }
  }, []);

  const penaltyForCancellationOptions = useMemo(
    () => [
      {
        label: t('attributes:typesOfPenalty.noPenalty'),
        value: 0,
      },
      {
        label: t('attributes:typesOfPenalty.fixedPenalty'),
        value: 1,
      },
      {
        label: t('attributes:typesOfPenalty.penaltyOnContract'),
        value: 2,
      },
    ],
    [t],
  );

  const newContractSteps = useMemo(
    () => [
      {
        title: t('attributes:client'),
        disableNextButton: !customerId,
        content: (
          <Row gutter={[16, 16]} justify="center">
            <Col lg={16} xl={16} xs={24}>
              <SelectCustomers
                showLabel
                value={customerId}
                onChange={setCustomerId}
              />
            </Col>
          </Row>
        ),
      },
      {
        title: t('newContract.typeOfContract'),
        disableNextButton:
          !selectedPlanType ||
          (selectedPlanType !== 4 && !operatorDiffChargeFlag),
        content: (
          <Row gutter={[16, 16]} justify="center">
            <Col lg={12} xl={12} xs={24}>
              <LabelModel text={t('newContract.typeOfContract')} />
              <Select
                placeholder={t('placeholders:typeOfContract')}
                style={{ width: '100%' }}
                value={selectedPlanType}
                onChange={handleSelectContractTypeChange}
              >
                <Option value={1}>{t('attributes:type.sharedWithout')}</Option>
                <Option value={2}>{t('attributes:type.sharedTotal')}</Option>
                {/* <Option value={3}>{t('attributes:type.sharedMixed')}</Option> */}
                <Option value={4}>{t('attributes:type.payPerUse')}</Option>
              </Select>
            </Col>
            {selectedPlanType && selectedPlanType !== 4 && (
              <Col lg={12} xl={12} xs={24}>
                <LabelModel text={t('attributes:chargingType.title')} />
                <Select
                  placeholder={t('placeholders:chargeType')}
                  style={{ width: '100%' }}
                  value={operatorDiffChargeFlag}
                  onChange={setOperatorDiffChargeFlag}
                >
                  <Option value={'N'}>
                    {t('attributes:chargingType.fixed')}
                  </Option>
                  <Option value={'S'}>
                    {t('attributes:chargingType.difPerOp')}
                  </Option>
                </Select>
              </Col>
            )}
          </Row>
        ),
      },
      {
        title: t('newContract.overflowRanges'),
        doNotShow: payPerUseFlag !== 'S',
        content: (
          <OverflowRangesConfig
            overflowRanges={defaultOverflowPricing}
            setOverflowRanges={setDefaultOverflowPricing}
            editable
          />
        ),
      },
      {
        title: t('newContract.difPerOperator'),
        doNotShow: operatorDiffChargeFlag !== 'S',
        content: (
          <DifPerOperatorConfig
            difPerOperator={defaultOperatorPricing}
            setDifPerOperator={setDefaultOperatorPricing}
          />
        ),
      },
      {
        title: t('newContract.config'),
        disableNextButton:
          !planDesc ||
          planDesc === '' ||
          (payPerUseFlag === 'N' &&
            operatorDiffChargeFlag === 'N' &&
            !motOperator),
        content: (
          <>
            <Row gutter={[16, 10]} style={{ marginBottom: 10 }} justify="start">
              <Col lg={12} xl={12} xs={24}>
                <LabelModel text={t('attributes:description')} isRequired />
                <Input
                  placeholder={t('placeholders:description')}
                  value={planDesc}
                  onChange={e => setPlanDesc(e.target.value)}
                  allowClear
                />
              </Col>
              {payPerUseFlag === 'N' && operatorDiffChargeFlag === 'N' && (
                <Col lg={12} xl={12} xs={24}>
                  <LabelModel text={t('attributes:operator')} isRequired />
                  <Select
                    options={operatorsOptions}
                    style={{ width: '100%' }}
                    placeholder={t('placeholders:operator')}
                    allowClear
                    showSearch
                    value={motOperator}
                    onChange={setMotOperator}
                  />
                </Col>
              )}
              <Col lg={12} xl={12} xs={24}>
                <LabelModel text={t('attributes:externalId')} />
                <Input
                  placeholder={t('placeholders:externalId')}
                  value={externalId}
                  onChange={e => setExternalId(e.target.value)}
                  allowClear
                />
              </Col>
            </Row>
            <Divider
              style={{
                marginTop: 40,
                fontSize: '0.85rem',
                opacity: 0.7,
                textTransform: 'uppercase',
              }}
            >
              {t('details.billingModel')}
            </Divider>
            <Row gutter={[16, 10]} style={{ marginBottom: 10 }}>
              {operatorDiffChargeFlag === 'N' && (
                <Col lg={12} xl={12} xs={24}>
                  <LabelModel text={t('attributes:price')} />
                  <InputNumber
                    defaultValue={price}
                    formatter={value => `R$ ${value}`}
                    min={0}
                    parser={value => value.replace('R$ ', '')}
                    step={0.01}
                    style={{ width: '100%' }}
                    onChange={value => setPrice(value)}
                  />
                </Col>
              )}
              <Col lg={12} xl={12} xs={24}>
                <LabelModel text={t('attributes:activationPrice')} />
                <InputNumber
                  defaultValue={activationPrice}
                  formatter={value => `R$ ${value}`}
                  min={0}
                  parser={value => value.replace('R$ ', '')}
                  step={0.01}
                  style={{ width: '100%' }}
                  onChange={value => setActivationPrice(value)}
                />
              </Col>
              <Col lg={12} xl={12} xs={24}>
                <LabelModel text={t('attributes:franchiseByTerminal')} />
                <InputNumber
                  defaultValue={franchise}
                  formatter={value => `${value} MB`}
                  min={0}
                  parser={value => value.replace(' MB', '')}
                  style={{ width: '100%' }}
                  onChange={value => setFranchiseByTerminal(value)}
                />
              </Col>
              {sharingType === 3 && (
                <Col lg={12} xl={12} xs={24}>
                  <LabelModel text={t('attributes:sharedFranchise')} />
                  <InputNumber
                    defaultValue={sharedFranchise}
                    formatter={value => `${value} MB`}
                    min={0}
                    parser={value => value.replace(' MB', '')}
                    style={{ width: '100%' }}
                    onChange={value => setSharedFranchise(value)}
                  />
                </Col>
              )}
              {payPerUseFlag === 'N' && (
                <Col lg={12} xl={12} xs={24}>
                  <LabelModel text={t('attributes:mbExcValue')} />
                  <InputNumber
                    defaultValue={mbExcValue}
                    formatter={value => `R$ ${value}`}
                    min={0}
                    parser={value => value.replace('R$ ', '')}
                    step={0.01}
                    style={{ width: '100%' }}
                    onChange={value => setMbExcValue(value)}
                  />
                </Col>
              )}
              <Col span={12} lg={12} xl={12} xs={24}>
                <LabelModel text={t('newContract.planType')} />
                <Select
                  value={planType}
                  onChange={setPlanType}
                  placeholder={t('newContract.selectPlanType')}
                  style={{ width: '100%' }}
                >
                  <Option value="STANDARD">
                    {t('newContract.planTypes.standard')}
                  </Option>
                  <Option value="BAIT">
                    {t('newContract.planTypes.bait')}
                  </Option>
                  {selectedPlanType !== 2 && (
                    <Option value="ALLOWANCE">
                      {t('newContract.planTypes.allowance')}
                    </Option>
                  )}
                </Select>
              </Col>
              {planType === 'ALLOWANCE' && (
                <>
                  <Col lg={12} xl={12} xs={24}>
                    <LabelModel text={t('newContract.planValidity')} />
                    <Select
                      value={planExpirationMonths}
                      onChange={setPlanExpirationMonths}
                      placeholder={t('newContract.selectPlanValidity')}
                      style={{ width: '100%' }}
                    >
                      <Option value={12}>12 {t('attributes:months')}</Option>
                      <Option value={24}>24 {t('attributes:months')}</Option>
                      <Option value={36}>36 {t('attributes:months')}</Option>
                    </Select>
                  </Col>
                  <Col lg={12} xl={12} xs={24}>
                    <Checkbox
                      style={{ marginTop: 16 }}
                      checked={automaticRenewal}
                      onChange={e => setAutomaticRenewal(e.target.checked)}
                    >
                      {t('newContract.autoRenewal')}
                    </Checkbox>
                  </Col>
                </>
              )}

              {planType === 'BAIT' && (
                <>
                  <Col lg={12} xl={12} xs={24}>
                    <LabelModel
                      text={t('attributes:iscaDurationInDays')}
                      isRequired
                    />
                    <Select
                      style={{ width: '100%' }}
                      value={iscaDurationInDays}
                      onChange={setIscaDurationInDays}
                      allowClear
                    >
                      {[30, 60, 90].map(item => (
                        <Option value={item} key={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col lg={12} xl={12} xs={24}>
                    <LabelModel text={t('attributes:iscaMaxRenewal')} />
                    <InputNumber
                      defaultValue={2}
                      min={0}
                      step={1}
                      style={{ width: '100%' }}
                      value={iscaMaxRenewal}
                      onChange={setIscaMaxRenewal}
                    />
                  </Col>
                </>
              )}
              {planType !== 'ALLOWANCE' && (
                <>
                  <Col lg={12} xl={12} xs={24}>
                    <LabelModel text={t('attributes:penaltyForCancellation')} />
                    <Select
                      options={penaltyForCancellationOptions}
                      style={{ width: '100%' }}
                      placeholder={t('placeholders:penaltyForCancellation')}
                      allowClear
                      showSearch
                      value={loyaltyType}
                      onChange={setLoyaltyType}
                    />
                  </Col>
                  {loyaltyType === 1 && (
                    <Col lg={12} xl={12} xs={24}>
                      <LabelModel text={t('attributes:penaltyAmount')} />
                      <InputNumber
                        defaultValue={0}
                        formatter={value => `R$ ${value}`}
                        min={0}
                        parser={value => value.replace('R$ ', '')}
                        step={0.01}
                        style={{ width: '100%' }}
                        value={penalty}
                        onChange={setPenalty}
                      />
                    </Col>
                  )}
                  {loyaltyType === 2 && (
                    <Col lg={12} xl={12} xs={24}>
                      <LabelModel
                        text={t('attributes:loyaltyTime')}
                        isRequired
                      />
                      <Select
                        style={{ width: '100%' }}
                        value={loyaltyTime}
                        onChange={setLoyaltyTime}
                        allowClear
                      >
                        {[12, 24, 36].map(item => (
                          <Option value={item} key={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                  )}
                </>
              )}
            </Row>
            <Row gutter={[16, 10]}>
              <Col lg={12} xl={12} xs={24}>
                <Checkbox
                  style={{ marginTop: 16 }}
                  checked={automaticBlocking}
                  onChange={e => setAutomaticBlocking(e.target.checked)}
                >
                  {t('attributes:automaticBlocking.title')}
                </Checkbox>
              </Col>
              <Col lg={12} xl={12} xs={24}>
                <Checkbox
                  style={{ marginTop: 16 }}
                  checked={activeRoaming}
                  onChange={e => setActiveRoaming(e.target.checked)}
                >
                  {t('attributes:activeRoaming')}
                </Checkbox>
              </Col>
            </Row>
          </>
        ),
      },
    ],
    [
      t,
      customerId,
      selectedPlanType,
      operatorDiffChargeFlag,
      handleSelectContractTypeChange,
      payPerUseFlag,
      defaultOverflowPricing,
      defaultOperatorPricing,
      planDesc,
      motOperator,
      operatorsOptions,
      externalId,
      price,
      activationPrice,
      franchise,
      sharingType,
      sharedFranchise,
      mbExcValue,
      penaltyForCancellationOptions,
      loyaltyType,
      penalty,
      loyaltyTime,
      automaticBlocking,
      activeRoaming,
      planType,
      planExpirationMonths,
      automaticRenewal,
      iscaDurationInDays,
      iscaMaxRenewal,
    ],
  );

  return (
    <ModalOfSteps
      title={t('newContract.title')}
      width="56%"
      handleConfirm={handleSaveContract}
      visible={modalVisible}
      steps={newContractSteps}
      onClose={cleanStateAndClose}
      confirmLoading={confirmLoading}
      disableConfirmButton={
        !planDesc || planDesc === '' || (loyaltyType === 2 && !loyaltyTime)
      }
    />
  );
};

export default NewContractModal;
