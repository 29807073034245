import ActionTypes from '@utils/deviceActions';

const doubleActions = t => ({
  network: {
    columns: {
      block: [
        {
          title: 'MSISDN',
          key: 'msisdn',
          dataIndex: 'msisdn',
        },
        {
          title: t('steps.network-block-unlock.block-type'),
          key: 'tpBloqueio',
          dataIndex: 'tpBloqueio',
          help: true,
        },
        {
          title: t('steps.network-block-unlock.initial-validity'),
          key: 'dtInicioBloqueio',
          dataIndex: 'dtInicioBloqueio',
        },
        {
          title: t('steps.network-block-unlock.end-validity'),
          key: 'dtFimBloqueio',
          dataIndex: 'dtFimBloqueio',
        },
      ],
      unlock: [
        {
          title: 'MSISDN',
          key: 'msisdn',
          dataIndex: 'msisdn',
        },
        {
          title: t('steps.network-block-unlock.unlock-date'),
          key: 'dtDesbloqueio',
          dataIndex: 'dtDesbloqueio',
          help: true,
        },
      ],
    },
    data: {
      block: [
        {
          key: '0',
          msisdn: 551699999999,
          tpBloqueio: 1,
          dtInicioBloqueio: '20211120',
          dtFimBloqueio: undefined,
        },
        {
          key: '1',
          msisdn: 551699999999,
          tpBloqueio: 2,
          dtInicioBloqueio: '20211120',
          dtFimBloqueio: undefined,
        },
        {
          key: '2',
          msisdn: 551699999999,
          tpBloqueio: 3,
          dtInicioBloqueio: '20211120',
          dtFimBloqueio: 20211124,
        },
      ],
      unlock: [
        {
          key: '0',
          msisdn: 551699999999,
          dtDesbloqueio: 20220116,
        },
        {
          key: '1',
          msisdn: 551699999999,
          dtDesbloqueio: 20220130,
        },
        {
          key: '2',
          msisdn: 551699999999,
          dtDesbloqueio: 20220201,
        },
      ],
    },
  },
  imei: {
    columns: {
      block: [
        {
          title: 'MSISDN',
          key: 'msisdn',
          dataIndex: 'msisdn',
        },
        {
          title: 'IMEI',
          key: 'processData',
          dataIndex: 'processData',
          help: true,
        },
      ],
      unlock: [
        {
          title: 'MSISDN',
          key: 'msisdn',
          dataIndex: 'msisdn',
        },
        {
          title: 'IMEI',
          key: 'processData',
          dataIndex: 'processData',
          help: true,
        },
      ],
    },
    data: {
      both: [
        {
          key: '0',
          msisdn: 551699999999,
          processData: 3566669999444456,
        },
      ],
    },
  },
});

export const getModelDataColumns = (actionType, t, lockUnlockType) => {
  switch (actionType) {
    case ActionTypes.ACTIVATION:
      return [
        {
          title: 'ICCID',
          dataIndex: 'iccid',
          key: 'iccid',
        },
        {
          title: 'IMSI',
          dataIndex: 'imsi',
          key: 'imsi',
        },
        {
          title: 'MSISDN',
          dataIndex: 'msisdn',
          key: 'msisdn',
        },
        {
          title: t('operator'),
          dataIndex: 'vendor',
          key: 'vendor',
          help: true,
        },
      ];
    case ActionTypes.NETWORK_RESET:
    case ActionTypes.SUSPENSION:
    case ActionTypes.WITHDRAWAL:
    case ActionTypes.SUSPENSION_WITHDRAWAL:
    case ActionTypes.SUSPENSION_CANCELL:
    case ActionTypes.MANUAL_ACTIVATION:
    case ActionTypes.HLR_REPROG:
    case ActionTypes.BAIT_RENEWAL:
    case ActionTypes.ALLOWANCE_PLAN_RENEWAL:
    case ActionTypes.FORCE_REACTIVATION:
      return [
        {
          title: 'MSISDN',
          dataIndex: 'msisdn',
          key: 'msisdn',
        },
      ];
    case ActionTypes.SIMCARD_RESET:
    case ActionTypes.PRIORITY_OPERATOR:
    case ActionTypes.OPERATOR_RESTRICTION:
      return [
        {
          title: 'MSISDN',
          dataIndex: 'msisdn',
          key: 'msisdn',
        },
        {
          title: 'ICCID',
          dataIndex: 'iccid',
          key: 'iccid',
        },
      ];
    case ActionTypes.TRANSFER:
      return [
        {
          title: t('transfer:batch-transfer.model.iccid'),
          key: 'iccid',
          dataIndex: 'iccid',
        },
        {
          title: t('transfer:batch-transfer.model.msisdn'),
          key: 'msisdn',
          dataIndex: 'msisdn',
        },
        {
          title: t('transfer:batch-transfer.model.orderId'),
          key: 'newCustomerVirtualAccountId',
          dataIndex: 'newCustomerVirtualAccountId',
          help: true,
        },
      ];
    case ActionTypes.CANCELLATION:
    case ActionTypes.MOT_DEACTIVATION:
      return [
        {
          title: 'ICCID',
          key: 'iccid',
          dataIndex: 'iccid',
        },
        {
          title: 'MSISDN',
          key: 'msisdn',
          dataIndex: 'msisdn',
        },
      ];
    case ActionTypes.NETWORK_BLOCK:
      return doubleActions(t).network.columns.block;
    case ActionTypes.NETWORK_UNLOCK:
      return doubleActions(t).network.columns.unlock;
    case ActionTypes.NETWORK_BLOCK_UNLOCK:
      if (lockUnlockType === 'block') {
        return doubleActions(t).network.columns.block;
      } else {
        return doubleActions(t).network.columns.unlock;
      }
    case ActionTypes.IMEI_LOCK:
      return doubleActions(t).imei.columns.block;
    case ActionTypes.IMEI_UNLOCK:
      return doubleActions(t).imei.columns.unlock;
    case ActionTypes.IMEI_LOCK_UNLOCK:
      if (lockUnlockType === 'block') {
        return doubleActions(t).imei.columns.block;
      } else {
        return doubleActions(t).imei.columns.unlock;
      }
    case ActionTypes.NICKNAME_CHANGE:
      return [
        {
          title: 'MSISDN',
          dataIndex: 'msisdn',
          key: 'msisdn',
        },
        {
          title: 'ICCID',
          dataIndex: 'iccid',
          key: 'iccid',
        },
        {
          title: t('steps.nickname-change.name'),
          dataIndex: 'processData',
          key: 'processData',
        },
      ];
    case ActionTypes.BROKER_SUBSTITUTION:
    case ActionTypes.BROKER_CANCELLATION_WAIVER:
    case ActionTypes.BROKER_CANCELLATION:
      return [
        {
          title: 'MSISDN',
          dataIndex: 'msisdn',
          key: 'msisdn',
        },
      ];
    case ActionTypes.MOT_OPERATOR_CHANGE:
      return [
        {
          title: 'MSISDN',
          dataIndex: 'msisdn',
          key: 'msisdn',
        },
        {
          title: t('steps.mot-operator-change.new-operator'),
          dataIndex: 'vendor',
          key: 'vendor',
          help: true,
        },
      ];
    case ActionTypes.PLASTIC_EXCHANGE:
      return [
        {
          title: 'MSISDN',
          dataIndex: 'msisdn',
          key: 'msisdn',
        },
        {
          title: 'ICCID',
          dataIndex: 'iccid',
          key: 'iccid',
        },
        {
          title: t('steps.exchange.name', {
            field: 'ICCID',
          }),
          dataIndex: 'processData',
          key: 'processData',
        },
      ];
    case ActionTypes.MSISDN_EXCHANGE:
      return [
        {
          title: 'MSISDN',
          dataIndex: 'msisdn',
          key: 'msisdn',
        },
        {
          title: 'ICCID',
          dataIndex: 'iccid',
          key: 'iccid',
        },
        {
          title: t('steps.exchange.name', {
            field: 'MSISDN',
          }),
          dataIndex: 'processData',
          key: 'processData',
        },
      ];
    case ActionTypes.ADD_CREDIT_TO_SIMCARD:
      return [
        {
          title: 'MSISDN',
          dataIndex: 'msisdn',
          key: 'msisdn',
        },
        {
          title: t('steps.add-credit.franchiseeMB'),
          dataIndex: 'processData',
          key: 'processData',
        },
      ];
    case ActionTypes.SMS_SEND:
      return [
        {
          title: 'MSISDN',
          dataIndex: 'msisdn',
          key: 'msisdn',
        },
      ];
    default:
      return [];
  }
};

export const getModelData = (actionType, lockUnlockType, t) => {
  switch (actionType) {
    case ActionTypes.ACTIVATION:
      return [
        {
          key: '1',
          iccid: '89553202000000159564',
          imsi: '724320200015956',
          msisdn: '5534912345678',
          vendor: 'ALGAR',
        },
        {
          key: '2',
          iccid: '89553202000000159847',
          imsi: '724320200015943',
          msisdn: '5534912345678',
          vendor: 'TIM',
        },
        {
          key: '3',
          iccid: '89553202000000159489',
          imsi: '724320200015912',
          msisdn: '5534912345678',
          vendor: 'CLARO',
        },
        {
          key: '4',
          iccid: '89553202000000159562',
          imsi: '724320200015465',
          msisdn: '5534912345678',
          vendor: 'VIVO',
        },
      ];
    case ActionTypes.NETWORK_RESET:
    case ActionTypes.SUSPENSION:
    case ActionTypes.WITHDRAWAL:
    case ActionTypes.SUSPENSION_WITHDRAWAL:
    case ActionTypes.MANUAL_ACTIVATION:
    case ActionTypes.HLR_REPROG:
    case ActionTypes.BAIT_RENEWAL:
    case ActionTypes.ALLOWANCE_PLAN_RENEWAL:
    case ActionTypes.FORCE_REACTIVATION:
      return [
        {
          key: '1',
          msisdn: '5503404446239',
        },
        {
          key: '2',
          msisdn: '5550030239500',
        },
        {
          key: '3',
          msisdn: '5575315909912',
        },
        {
          key: '4',
          msisdn: '5541857845871',
        },
      ];
    case ActionTypes.SIMCARD_RESET:
    case ActionTypes.PRIORITY_OPERATOR:
    case ActionTypes.OPERATOR_RESTRICTION:
      return [
        {
          key: '1',
          iccid: '89553202000000159564',
          msisdn: '5503404446239',
        },
        {
          key: '2',
          iccid: '89553202000000159847',
          msisdn: '5503404446239',
        },
        {
          key: '3',
          iccid: '89553202000000159489',
          msisdn: '5503404446239',
        },
        {
          key: '4',
          iccid: '89553202000000159562',
          msisdn: '5503404446239',
        },
      ];
    case ActionTypes.TRANSFER:
      return [
        {
          iccid: 89553202000000159562,
          msisdn: 551699999999,
          newCustomerVirtualAccountId: 321,
        },
      ];
    case ActionTypes.CANCELLATION:
    case ActionTypes.BROKER_CANCELLATION:
    case ActionTypes.BROKER_CANCELLATION_WAIVER:
    case ActionTypes.BROKER_SUBSTITUTION:
      return [
        {
          msisdn: 551699999999,
        },
      ];
    case ActionTypes.MOT_DEACTIVATION:
      return [
        {
          iccid: 89553202000000159562,
          msisdn: 551699999999,
        },
      ];
    case ActionTypes.NETWORK_BLOCK:
      return doubleActions(t).network.data.block;
    case ActionTypes.NETWORK_UNLOCK:
      return doubleActions(t).network.data.unlock;
    case ActionTypes.NETWORK_BLOCK_UNLOCK:
      if (lockUnlockType === 'block') {
        return doubleActions(t).network.data.block;
      } else {
        return doubleActions(t).network.data.unlock;
      }
    case ActionTypes.IMEI_LOCK:
      return doubleActions(t).imei.data.block;
    case ActionTypes.IMEI_UNLOCK:
      return doubleActions(t).imei.data.unlock;
    case ActionTypes.IMEI_LOCK_UNLOCK:
      return doubleActions(t).imei.data.both;
    case ActionTypes.NICKNAME_CHANGE:
      return [
        {
          iccid: 89553202000000159562,
          msisdn: 551699999999,
          processData: t('steps.nickname-change.new-nickname'),
        },
      ];
    case ActionTypes.PLASTIC_EXCHANGE:
      return [
        {
          iccid: 89237502000000159562,
          msisdn: 551699999999,
          processData: 89553202000000173849,
        },
      ];

    case ActionTypes.MSISDN_EXCHANGE:
      return [
        {
          iccid: 89237502000000159562,
          msisdn: 551699999999,
          processData: 551688888888,
        },
      ];
    case ActionTypes.MOT_OPERATOR_CHANGE:
      return [
        {
          msisdn: 551699999999,
          vendor: 'CLARO',
        },
      ];
    case ActionTypes.ADD_CREDIT_TO_SIMCARD:
      return [
        {
          msisdn: 551699999999,
          processData: 10,
        },
      ];
    case ActionTypes.SMS_SEND:
      return [
        {
          msisdn: 551699999999,
        },
        {
          msisdn: 551688888888,
        },
      ];
    default:
      return [];
  }
};
