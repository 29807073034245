import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Input,
  Button,
  Tooltip,
  message,
  Dropdown,
  Menu,
  Col,
  Select,
  InputNumber,
  List,
  Typography,
} from 'antd';
import { Label } from '@components/ui';
import { useTranslation } from 'react-i18next';
import Table from '@components/Table';
import useSwr from '@hooks/useSwr';
import KeycloakContext from '@store/KeycloakContext/KeycloakContext';
import { PlusCircleOutlined, ControlOutlined } from '@ant-design/icons';
import apiMiddleware from '@services/apiMiddleware';
import AdminActions from '@components/AdminActions';
import CustomerModal from './CustomerViewerModal';
import * as Styled from './styles';
import SessionContext from '@store/SessionContext/SessionContext';
import useSearchParams from '@src/hooks/useSearchParams';
import FinancialBlockStatusColumn from './FinancialBlockStatusColumn/index';
import { Filters, FiltersCard } from '@src/components/Filters/index';
import rolesConstants from '@src/utils/rolesConstants';
import RequestReport from '@src/components/RequestReport/RequestReport';
import CustomerActionsModal from './CustomerActionsModal/CustomerActionsModal';
import RequestCancelReports from './RequestCancelReports/index';
import RequestSmsManageReports from './RequestSmsManageReports/index';

const { Search } = Input;

const DEFAULT_PAGINATION = {
  current: 1,
  defaultCurrent: 1,
  defaultPageSize: 10,
  pageSize: 10,
  pageSizeOptions: ['10', '20', '30'],
  style: { marginRight: 20 },
};

const Customers = () => {
  const { t } = useTranslation(['customers', 'reports']);
  const { keycloak } = useContext(KeycloakContext);
  const { userRoles, handleMutateCustomerSwitch } = useContext(SessionContext);

  const isCustomerCanManage =
    userRoles?.portal?.indexOf('manage-customers') !== -1;
  const isNotCustomer = keycloak?.idTokenParsed?.customerType !== 'CUSTOMER';
  const isSuperBroker = keycloak?.idTokenParsed?.customerType === 'SUPERBROKER';

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [customerId, setCustomerId] = useState(-1);
  const [showFilters, setShowFilters] = useState(false);
  const [name, setName] = useState();
  const [nameValue, setNameValue] = useState();
  const [cpfCnpj, setCpfCnpj] = useState();
  const [cpfCnpjValue, setCpfCnpjValue] = useState();
  const [customerType, setCustomerType] = useState();
  const [financialBlock, setFinancialBlock] = useState();
  const [dueDay, setDueDay] = useState();
  const [isModalActionsVisible, setIsModalActionsVisible] = useState(false);
  const [activeAction, setActiveAction] = useState();
  const [tableSelection, setTableSelection] = useState();
  const [showRequestReport, setShowRequestReport] = useState(false);
  const [showRequestReportSms, setShowRequestReportSms] = useState(false);

  const nbMonthsToBillAfter = useMemo(
    () => ({
      0: t('attributes.thirtyDays'),
      1: t('attributes.sixtyDays'),
      2: t('attributes.ninetyDays'),
    }),
    [t],
  );

  const { data: newApnData } = useSwr(
    keycloak?.idTokenParsed?.customerId
      ? `/service-proxy/apns-customer/${keycloak?.idTokenParsed?.customerId}`
      : null,
  );

  const filterParams = useMemo(
    () => ({
      name,
      cpfCnpj,
      customerType: customerType?.toUpperCase(),
      financialBlock,
      dueDay,
    }),
    [name, cpfCnpj, customerType, financialBlock, dueDay],
  );

  const { data: customersData, mutate } = useSwr(
    '/service-proxy/broker/customers',
    {
      page: pagination.current ? pagination.current - 1 : 0,
      linesPerPage: pagination.pageSize,
      ...filterParams,
    },
  );

  const deleteCustomer = useCallback(
    idToDelete => apiMiddleware.delete(`/customers/${idToDelete}`),
    [],
  );

  const paramsAttributes = useMemo(
    () => [
      {
        name: 'name',
        setState: setName,
        inTheFilters: true,
      },
      {
        name: 'cpfCnpj',
        setState: setCpfCnpj,
        inTheFilters: true,
      },
      {
        name: 'customerType',
        setState: setCustomerType,
        inTheFilters: true,
      },
      {
        name: 'financialBlock',
        setState: setFinancialBlock,
        inTheFilters: true,
      },
      {
        name: 'dueDay',
        setState: setDueDay,
        inTheFilters: true,
      },
    ],
    [],
  );

  const { handleSetSearchParams, handleClearParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  useEffect(() => {
    setNameValue(name);
  }, [name]);

  useEffect(() => {
    setCpfCnpjValue(cpfCnpj);
  }, [cpfCnpj]);

  useEffect(() => {
    if (dueDay === '0') {
      handleSetSearchParams({ dueDay: undefined });
    }
  }, [dueDay, handleSetSearchParams]);

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      current: 1,
    }));
  }, [name, cpfCnpj, customerType, financialBlock, dueDay]);

  useEffect(() => {
    if (customersData) {
      setPagination(oldPagination => ({
        ...oldPagination,
        total: customersData?.totalElements,
      }));
    }
  }, [customersData]);

  const handleMutateCustomers = useCallback(
    (type, data) => {
      if (type === 'CUSTOMER_TO_BROKER') {
        mutate();
        return;
      }
      if (customersData) {
        handleMutateCustomerSwitch(data, type);
        if (type === 'CREATE') {
          mutate({
            ...customersData,
            content: [...customersData.content, data],
          });
        } else {
          mutate({
            ...customersData,
            content: customersData.content.filter(
              customer => customer.id !== data.id,
            ),
          });
        }
      }
    },
    [customersData, mutate, handleMutateCustomerSwitch],
  );

  const handleActions = useCallback(
    async (actionType, record) => {
      // actionType = 'UPDATE' || 'DELETE'
      const isUpdate = actionType === 'UPDATE';
      if (isUpdate) {
        setCustomerId(record.id);
        setIsModalVisible(true);
      } else {
        try {
          await deleteCustomer(record.id);
          handleMutateCustomers('DELETE', { id: record.id });
          message.success(t('msgs.success-deleting-customer'));
        } catch {
          message.error(t('msgs.error-deleting-customer'));
        }
      }
    },
    [deleteCustomer, handleMutateCustomers, t],
  );

  const formatType = useCallback(type => {
    if (type === 'SUPERBROKER') {
      return 'Broker';
    }
    if (type === 'BROKER') {
      return 'Sub-broker';
    }
    return 'Cliente final';
  }, []);

  const handleMutateFinancialBlock = useCallback(
    (cstId, financialBlock) => {
      mutate({
        ...customersData,
        content: customersData?.content?.map(customer =>
          customer.id === cstId
            ? {
                ...customer,
                financialBlock: !financialBlock,
              }
            : customer,
        ),
      });
    },
    [customersData, mutate],
  );

  const columnApn = useCallback(
    value => {
      return (
        value?.length > 0 && (
          <Tooltip
            title={
              <>
                <List
                  dataSource={value}
                  renderItem={item => (
                    <List.Item style={{ padding: '18px' }}>
                      <Typography.Text>{item.nameApn}</Typography.Text>{' '}
                    </List.Item>
                  )}
                  style={{
                    maxHeight: '200px',
                    overflow: 'auto',
                    backgroundColor: 'white',
                  }}
                />
              </>
            }
            className="apn-tooltip"
          >
            <Button type="link">
              {`${value.length} ${t('attributes:apn')}`}
            </Button>
          </Tooltip>
        )
      );
    },
    [t],
  );

  const columnsDefault = useMemo(() => {
    return [
      {
        title: t('attributes.id'),
        dataIndex: 'id',
      },
      {
        title: t('attributes.name'),
        dataIndex: 'name',
      },
      {
        title: t('attributes.nickname'),
        dataIndex: 'nickname',
      },
      {
        title: t('attributes.tradingName'),
        dataIndex: 'tradingName',
      },
      {
        title: t('attributes.cpfCnpj'),
        dataIndex: 'cpfCnpj',
      },
      {
        title: t('attributes.apn'),
        dataIndex: 'apns',
        render: columnApn,
      },
      {
        title: t('attributes.type'),
        dataIndex: 'type',
        render: value => formatType(value),
        visible: isNotCustomer,
      },
      {
        title: t('attributes.dueDay'),
        dataIndex: 'dueDay',
        align: 'center',
        render: (value, record) => (
          <>
            {value} {record.secondDueDay ? `, ${record.secondDueDay}` : ''}
          </>
        ),
      },
      {
        title: t('attributes.financialBlockStatus'),
        dataIndex: 'financialBlock',
        align: 'center',
        render: (value, record) => (
          <FinancialBlockStatusColumn
            value={value}
            customerId={record.id}
            mutate={() => handleMutateFinancialBlock(record.id, value)}
          />
        ),
      },
    ];
  }, [formatType, handleMutateFinancialBlock, t, isNotCustomer, columnApn]);

  const columns = useMemo(() => {
    if (isNotCustomer) {
      columnsDefault.push({
        title: t('attributes.ignoreErpSync'),
        dataIndex: 'ignoreErpSync',
        align: 'center',
        render: value => (value ? t('attributes.yes') : t('attributes.no')),
      });
      columnsDefault.push({
        title: t('attributes.billing'),
        dataIndex: 'nbMonthsToBillAfter',
        align: 'center',
        render: value => nbMonthsToBillAfter[value] ?? '',
      });
    }
    if (isCustomerCanManage) {
      columnsDefault.push({
        title: '',
        dataIndex: 'actions',
        align: 'center',
        render: (text, record) => (
          <AdminActions
            alertDelete={t('form.msgs.deleteAlert')}
            entityToExclude={record.name}
            ruleToNotExclude={record.id === keycloak.idTokenParsed?.customerId}
            onEdit={() => handleActions('UPDATE', record)}
            onDelete={() => handleActions('DELETE', record)}
          />
        ),
      });
    }

    // conditional rendering of the type column
    return columnsDefault.filter(
      col => col.visible || col.dataIndex !== 'type',
    );
  }, [
    columnsDefault,
    handleActions,
    isCustomerCanManage,
    keycloak.idTokenParsed?.customerId,
    t,
    isNotCustomer,
    nbMonthsToBillAfter,
  ]);

  const handleTableChange = useCallback(paginationConf => {
    if (paginationConf) {
      setPagination(() => ({
        ...paginationConf,
        linesPerPage: paginationConf.pageSize,
      }));
    }
  }, []);

  const onClose = useCallback(() => {
    setIsModalVisible(false);
    setCustomerId(-1);
  }, []);

  const handleNewCustomer = useCallback(() => {
    setCustomerId(-1);
    setIsModalVisible(true);
  }, []);

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        handleClearParams();
      }
    },
    [showFilters, handleClearParams],
  );

  const tableFilters = useMemo(
    () => [
      {
        visible: true,
        col: {
          lg: 12,
          xl: 8,
          xs: 24,
        },
        label: t('attributes.name'),
        item: (
          <Search
            placeholder={t('attributes.name')}
            onSearch={value => handleSetSearchParams({ name: value })}
            onChange={({ target: { value } }) => setNameValue(value)}
            value={nameValue}
            allowClear
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 12,
          xl: 8,
          xs: 24,
        },
        label: t('attributes.cpfCnpj'),
        item: (
          <Search
            placeholder={t('attributes.cpfCnpj')}
            onSearch={value => handleSetSearchParams({ cpfCnpj: value })}
            onChange={({ target: { value } }) => setCpfCnpjValue(value)}
            value={cpfCnpjValue}
            allowClear
          />
        ),
      },
      {
        visible: isNotCustomer,
        col: {
          lg: 6,
          xl: 4,
          xs: 12,
        },
        label: t('attributes.type'),
        item: (
          <Select
            optionFilterProp={'label'}
            options={[
              {
                label: t('attributes.customerTypes.broker'),
                value: 'broker',
              },
              {
                label: t('attributes.customerTypes.customer'),
                value: 'customer',
              },
            ]}
            placeholder={t('attributes.type')}
            style={{ width: '100%' }}
            allowClear
            showSearch
            value={customerType}
            onChange={value =>
              handleSetSearchParams({ customerType: value?.toLowerCase() })
            }
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 6,
          xl: 4,
          xs: 12,
        },
        label: t('attributes.dueDay'),
        item: (
          <InputNumber
            value={dueDay}
            min={0}
            max={31}
            placeholder={t('attributes.dueDay')}
            step={1}
            style={{ width: '100%' }}
            onChange={value =>
              handleSetSearchParams({ dueDay: value !== 0 ? value : undefined })
            }
            allowClear
          />
        ),
      },
      {
        visible: true,
        col: {
          lg: 12,
          xl: 8,
          xs: 24,
        },
        label: t('attributes.financialBlockStatus'),
        item: (
          <Select
            optionFilterProp={'label'}
            options={[
              {
                label: t('actions.financialBlock.blocked'),
                value: 'true',
              },
              {
                label: t('actions.financialBlock.unlocked'),
                value: 'false',
              },
            ]}
            placeholder={t('attributes.financialBlockStatus')}
            style={{ width: '100%' }}
            allowClear
            showSearch
            value={financialBlock}
            onChange={value =>
              handleSetSearchParams({
                financialBlock: value,
              })
            }
          />
        ),
      },
    ],
    [
      handleSetSearchParams,
      t,
      nameValue,
      cpfCnpjValue,
      customerType,
      financialBlock,
      dueDay,
      isNotCustomer,
    ],
  );

  const reportsItems = useMemo(
    () => [
      {
        role: rolesConstants.REQUEST_CUSTOMER_REPORT,
        filters: filterParams,
        path: '/service-proxy/broker/customers/report',
        type: 'CUSTOMERS',
        isParams: true,
      },
      {
        role: rolesConstants.MANAGEMENT_REPORT,
        path: '/service-proxy/inventory/MOT/management-report',
        type: 'MANAGEMENT',
        secondaryDescription: true,
      },
      {
        role: rolesConstants.MANAGEMENT_REPORT,
        path: '/service-proxy/broker/cancellation/report',
        type: 'CANCELLATION_MANAGEMENT',
        secondaryDescription: true,
        action: () => setShowRequestReport(true),
      },
      ...(isSuperBroker
        ? [
            {
              role: rolesConstants.MANAGEMENT_REPORT,
              path: '/service-proxy/sms/manage/report',
              type: 'MANAGE_SMS',
              secondaryDescription: true,
              action: () => setShowRequestReportSms(true),
            },
          ]
        : []),
    ],
    [filterParams, isSuperBroker],
  );

  const actionsRoles = useMemo(
    () => [rolesConstants.FINANCIAL_LOCK_UNLOCK],
    [],
  );

  const checkRole = useCallback(
    role => userRoles?.portal?.includes(role),
    [userRoles],
  );

  const hasAnyBatchActionRole = useMemo(
    () => actionsRoles?.some(role => checkRole(role)),
    [actionsRoles, checkRole],
  );

  const menu = useMemo(
    () => (
      <Menu
        inlineIndent={8}
        onClick={({ key }) => {
          setActiveAction(key);
          setIsModalActionsVisible(true);
        }}
      >
        <Menu.ItemGroup title={t('actions.title')}>
          {actionsRoles.map(role => {
            const hasBatchRole = checkRole(role);

            if (!hasBatchRole) {
              return;
            }

            return (
              <Menu.Item key={role}>{t(`actions.${role}.title`)}</Menu.Item>
            );
          })}
        </Menu.ItemGroup>
      </Menu>
    ),
    [actionsRoles, checkRole, t],
  );

  const customersSelectedInTheTable = useMemo(
    () =>
      tableSelection?.map(id =>
        customersData?.content?.find(customer => customer.id === id),
      ),
    [tableSelection, customersData],
  );

  return (
    <>
      {isModalVisible && (
        <CustomerModal
          customerId={customerId}
          visible={isModalVisible}
          onClose={onClose}
          mutateData={handleMutateCustomers}
          newApnData={newApnData}
        />
      )}
      {hasAnyBatchActionRole && (
        <CustomerActionsModal
          visible={isModalActionsVisible}
          activeAction={activeAction}
          selectedCustomers={customersSelectedInTheTable}
          onClose={() => {
            setIsModalActionsVisible(false);
            setActiveAction(undefined);
          }}
        />
      )}

      <RequestCancelReports
        visible={showRequestReport}
        onClose={() => setShowRequestReport(false)}
      />

      <RequestSmsManageReports
        visible={showRequestReportSms}
        onClose={() => setShowRequestReportSms(false)}
      />

      <Styled.Container>
        <Table
          columns={columns}
          allColumns={columnsDefault}
          data={customersData?.content || []}
          loading={!customersData?.content}
          title={
            <>
              {t('customers')}
              <Filters
                showFilters={showFilters}
                setShowFilters={handleShowFilters}
              />
            </>
          }
          tableKey="customers"
          pagination={pagination}
          onChange={handleTableChange}
          selectable
          setSelectedRowKeys={setTableSelection}
          extraActions={
            <>
              {isCustomerCanManage && (
                <Tooltip placement="top" title={t('modal.title.newCustomer')}>
                  <Button
                    icon={<PlusCircleOutlined />}
                    size="large"
                    type="link"
                    onClick={() => handleNewCustomer()}
                  />
                </Tooltip>
              )}
              <RequestReport items={reportsItems} />
              {hasAnyBatchActionRole && (
                <Dropdown overlay={menu} overlayClassName={'overlay-menu'}>
                  <Tooltip title={t('actions.title')}>
                    <Button
                      icon={<ControlOutlined />}
                      size="large"
                      type="link"
                    />
                  </Tooltip>
                </Dropdown>
              )}
            </>
          }
          extraFilters={
            showFilters && (
              <FiltersCard>
                {tableFilters?.map(
                  ({ col: { lg, xl, xs }, label, item, visible }) =>
                    visible && (
                      <Col key={label} lg={lg} xl={xl} xs={xs}>
                        {label && (
                          <div>
                            <Label color={'#575962'} htmlFor={label}>
                              {label}
                            </Label>
                          </div>
                        )}
                        {item}
                      </Col>
                    ),
                )}
              </FiltersCard>
            )
          }
        />
      </Styled.Container>
    </>
  );
};

export default Customers;
