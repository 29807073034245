import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { formatDate } from '@utils/formatters';
import {
  Select,
  message,
  InputNumber,
  Input,
  Checkbox,
  Row,
  Col,
  Tooltip,
  Divider,
  Button,
  Tag,
  Alert,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import OverflowRangesConfig from '@components/ui/OverflowRangesConfig/OverflowRangesConfig';
import DifPerOperatorConfig from '@components/ui/DifPerOperatorConfig/DifPerOperatorConfig';
import ParametrizedDatePicker from '@src/components/DatePicker/DatePicker';
import moment from 'moment';
import ModalOfSteps from '@src/components/ModalOfSteps/ModalOfSteps';
import apiMiddleware from '@src/services/apiMiddleware';
import LabelModel from '@src/components/LabelModel/LabelModel';
import SelectCustomers from '@src/components/SelectCustomers/index';
import TagInput from '@src/components/TagInput/index';
import useSwr from '@src/hooks/useSwr';

const { Option } = Select;

const NewRequestModal = ({
  visible,
  onClose,
  newRequestMutate,
  defaultCustomerId,
  defaultContractId,
  showContracted,
}) => {
  const { t } = useTranslation([
    'requests',
    'attributes',
    'placeholders',
    'contracts',
  ]);

  const [modalVisible, setModalVisible] = useState(false);
  const [price, setPrice] = useState();
  const [activationPrice, setActivationPrice] = useState();
  const [megabyteExcPrice, setMegabyteExcPrice] = useState();
  const [description, setDescription] = useState();
  const [automaticBlocking, setAutomaticBlocking] = useState(false);
  const [loyaltyType, setLoyaltyType] = useState(0);
  const [loyaltyTime, setLoyaltyTime] = useState();
  const [penalty, setPenalty] = useState(0);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [activationDate, setActivationDate] = useState();
  const [tradeInMonths, setTradeInMonths] = useState();
  const [minimumConsumption, setMinimumConsumption] = useState({
    value: 1,
    type: 'B',
  });
  const [selectedClient, setSelectedClient] = useState();
  const [virtualPlanId, setVirtualPlanId] = useState();
  const [overflowRanges, setOverflowRanges] = useState([]);
  const [difPerOperator, setDifPerOperator] = useState([]);
  const [operatorDiffChargeFlag, setOperatorDiffChargeFlag] = useState();
  const [payPerUseFlag, setPayPerUseFlag] = useState();
  const [nbPreActivationDays, setNbPreActivationDays] = useState();
  const [blockedByDefault, setBlockedByDefault] = useState();
  const [chargeActivationFeeOnTransfer, setChargeActivationFeeOnTransfer] =
    useState();
  const [contractsByClient, setContractsByClient] = useState([]);
  const [hasNoContracts, setHasNoContracts] = useState(false);
  const [trackingNumbers, setTrackingNumbers] = useState();
  const [activeRoaming, setActiveRoaming] = useState(false);
  const [planExpirationMonths, setPlanExpirationMonths] = useState();
  const [automaticRenewal, setAutomaticRenewal] = useState(false);
  const [chargeIscaOnTransfer, setChargeIscaOnTransfer] = useState(false);
  const [iscaDurationInDays, setIscaDurationInDays] = useState();
  const [iscaMaxRenewal, setIscaMaxRenewal] = useState(2);

  const { data: contractData } = useSwr(
    selectedClient && defaultContractId
      ? `/service-proxy/broker/virtual-plan/${defaultContractId}?showContracted=${showContracted}`
      : null,
  );

  useEffect(() => {
    if (selectedClient && !defaultContractId) {
      apiMiddleware
        .get(`/service-proxy/broker/${selectedClient}/virtual-plan`)
        .then(res => {
          if (res?.status === 204) {
            setHasNoContracts(true);
          } else {
            setHasNoContracts(false);
            setContractsByClient(res?.data);
          }
        });
    }
  }, [defaultContractId, selectedClient]);

  const contractsByClientOptions = useMemo(
    () =>
      contractsByClient !== '' &&
      contractsByClient?.map(({ id, planDesc }) => ({
        label: planDesc,
        value: id,
      })),
    [contractsByClient],
  );

  const contractDetails = useMemo(() => {
    if (selectedClient && defaultContractId && contractData) {
      return contractData.virtualContract;
    }
    if (contractsByClient !== '') {
      return contractsByClient?.find(contract => contract.id === virtualPlanId);
    }
  }, [
    contractsByClient,
    virtualPlanId,
    selectedClient,
    defaultContractId,
    contractData,
  ]);

  useEffect(() => {
    setOverflowRanges(
      contractDetails?.defaultOverflowPricing?.map(item => ({
        nuFaixaFinalBytes: item.chargingFinalBytes,
        nuFaixaInicialBytes: item.chargingInitialBytes,
        nuCobrancaMinimaBytes: item.chargingMinimalBytes,
        nuCobrancaIncrementalBytes: item.chargingIncrementalBytes,
        nuValorNegociadoMbyte: item.megabyteChargeValue,
        idOperadora: item.operatorId,
      })),
    );
  }, [contractDetails?.defaultOverflowPricing]);

  useEffect(() => {
    setDifPerOperator(
      contractDetails?.defaultOperatorPricing?.map(item => ({
        idOperadora: item.operatorId,
        nuValorLiquido: item.chargingValue,
      })),
    );
  }, [contractDetails?.defaultOperatorPricing]);

  useEffect(() => {
    if (defaultContractId) {
      setVirtualPlanId(defaultContractId);
    }
    if (defaultCustomerId) {
      setSelectedClient(defaultCustomerId);
    }
  }, [defaultContractId, defaultCustomerId]);

  useEffect(() => {
    if (contractDetails) {
      setPrice(contractDetails.price);
      setActivationPrice(contractDetails.activationPrice);
      setMegabyteExcPrice(contractDetails.megabyteExcPrice);
      setLoyaltyType(contractDetails.loyaltyType);
      if (contractDetails.loyaltyType === 2) {
        setLoyaltyTime(contractDetails.loyaltyTime);
      }
      setPenalty(contractDetails.penalty);
      setAutomaticBlocking(contractDetails.automaticBlocking);
      setOperatorDiffChargeFlag(contractDetails.operatorDiffChargeFlag);
      setPayPerUseFlag(contractDetails.payPerUseFlag);
      setActiveRoaming(contractDetails.roaming ?? false);
      setPlanExpirationMonths(contractDetails.planExpirationMonths);
      setAutomaticRenewal(contractDetails.automaticRenewal);
      setIscaDurationInDays(contractDetails.iscaDurationInDays);
      setIscaMaxRenewal(contractDetails.iscaMaxRenewal);
    }
  }, [contractDetails]);

  const createAccount = useCallback(
    payload =>
      apiMiddleware.post('/service-proxy/broker/virtual-account', payload),
    [],
  );

  const cleanStateAndClose = useCallback(
    (cancelled = false) => {
      setModalVisible(false);
      setPrice(0);
      setActivationPrice(0);
      setMegabyteExcPrice(0);
      setDescription(undefined);
      setAutomaticBlocking(false);
      setLoyaltyType(0);
      setLoyaltyTime(undefined);
      setPenalty(0);
      setConfirmLoading(false);
      setActivationDate(undefined);
      setTradeInMonths(undefined);
      setDescription(undefined);
      setMinimumConsumption({ value: 1, type: 'B' });
      setSelectedClient(undefined);
      setVirtualPlanId(undefined);
      setOverflowRanges(undefined);
      setDifPerOperator(undefined);
      setOperatorDiffChargeFlag(undefined);
      setPayPerUseFlag(undefined);
      setNbPreActivationDays(undefined);
      setBlockedByDefault(undefined);
      setChargeActivationFeeOnTransfer(undefined);
      setActiveRoaming(false);
      setPlanExpirationMonths(undefined);
      setAutomaticRenewal(false);
      setChargeIscaOnTransfer(false);
      setIscaDurationInDays(undefined);
      setIscaMaxRenewal(2);
      setChargeIscaOnTransfer(false);
      onClose(cancelled);
    },
    [onClose],
  );

  useEffect(() => {
    setModalVisible(visible);
  }, [visible]);

  // convert values to bytes
  const handleMinimumConsumption = useCallback(() => {
    const { value, type } = minimumConsumption;
    const valueInNumber = parseInt(value, 10);

    if (type === 'KB') {
      return valueInNumber * 1024;
    } else if (type === 'MB') {
      return valueInNumber * 1024 * 1024;
    }
    return valueInNumber;
  }, [minimumConsumption]);

  const handleCreateRequest = useCallback(async () => {
    // save account
    setConfirmLoading(true);
    const ppOperadoraInfos = difPerOperator?.map(item => {
      return {
        ...item,
        dtAtivacao: formatDate(new Date(), 'yyyyMMdd'),
      };
    });

    const overflowRangesFormatted = overflowRanges?.map(item => {
      return {
        ...item,
        dtAtivacao: formatDate(new Date(), 'yyyyMMdd'),
      };
    });

    const payload = {
      overflowRanges: overflowRangesFormatted ?? [],
      ppOperadoraInfos: ppOperadoraInfos ?? [],
      price,
      name: description,
      megabyteExcPrice,
      activationPrice,
      virtualPlanId,
      automaticBlocking,
      loyaltyType,
      loyaltyTime,
      penalty,
      activationDate,
      tradeInMonths,
      preActivationExemptTraffic: handleMinimumConsumption(),
      nbPreActivationDays: nbPreActivationDays ?? 0,
      blockedByDefault,
      chargeActivationFeeOnTransfer,
      trackingNumbers,
      roaming: activeRoaming,
      planType: contractDetails?.planType,
      planExpirationMonths,
      automaticRenewal,
      iscaDurationInDays,
      iscaMaxRenewal,
      chargeIscaOnTransfer,
    };

    if (!payload.activationDate) {
      payload.activationDate = moment().toISOString().split('T')[0];
    }

    try {
      await createAccount(payload).then(() => {
        newRequestMutate(payload);
        message.success(t('modal.notifications.success'));
      });
    } catch {
      message.error(t('modal.notifications.error'));
    }

    cleanStateAndClose(true);
    setConfirmLoading(false);
  }, [
    difPerOperator,
    overflowRanges,
    price,
    description,
    megabyteExcPrice,
    activationPrice,
    virtualPlanId,
    automaticBlocking,
    loyaltyType,
    loyaltyTime,
    penalty,
    activationDate,
    tradeInMonths,
    handleMinimumConsumption,
    nbPreActivationDays,
    blockedByDefault,
    chargeActivationFeeOnTransfer,
    trackingNumbers,
    activeRoaming,
    contractDetails?.planType,
    planExpirationMonths,
    automaticRenewal,
    chargeIscaOnTransfer,
    cleanStateAndClose,
    createAccount,
    newRequestMutate,
    iscaDurationInDays,
    iscaMaxRenewal,
    t,
  ]);

  const penaltyForCancellationOptions = useMemo(
    () => [
      {
        label: t('attributes:typesOfPenalty.noPenalty'),
        value: 0,
      },
      {
        label: t('attributes:typesOfPenalty.fixedPenalty'),
        value: 1,
      },
      {
        label: t('attributes:typesOfPenalty.penaltyOnContract'),
        value: 2,
      },
    ],
    [t],
  );

  const nbPreActivationDaysOptions = useMemo(() => {
    const numOptions = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 30, 45, 60, 90, 120,
    ].map(num => ({
      label: t('attributes:day', { count: num }),
      value: num,
    }));
    return [
      {
        label: t('attributes:noPreActivation'),
        value: 0,
      },
      ...numOptions,
    ];
  }, [t]);

  const sharingType = useMemo(() => {
    const type = contractDetails?.sharingType;
    if (!type) {
      return;
    }
    const isPayPerUse =
      contractDetails?.payPerUseFlag === 'S' &&
      contractDetails?.operatorDiffChargeFlag === 'N';
    if (isPayPerUse) {
      return t('attributes:type.payPerUse');
    }
    const typeAttr = t(`attributes:type.${type}`);
    return t(`attributes:${typeAttr}`);
  }, [t, contractDetails]);

  const DescriptionModel = useCallback(
    ({ label, text }) => (
      <Tag
        style={{
          margin: 4,
          opacity: 0.6,
          padding: '2px 8px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span
          style={{ fontWeight: 'bold', fontSize: '0.8rem' }}
        >{`${label}: `}</span>
        <span style={{ fontSize: '1rem', marginLeft: 8 }}>{text}</span>
      </Tag>
    ),
    [],
  );
  useEffect(() => {
    if (contractDetails?.franchise === 0) {
      setBlockedByDefault(true);
    }
  }, [contractDetails?.franchise]);

  const newRequestSteps = useMemo(
    () => [
      {
        title: t('attributes:client'),
        disableNextButton:
          !selectedClient || !contractsByClient || hasNoContracts,
        doNotShow: defaultCustomerId,
        loadingNextButton: selectedClient && !contractsByClient,
        content: (
          <Row gutter={[16, 16]} justify="center">
            <Col lg={16} xl={16} xs={24}>
              <SelectCustomers
                showLabel
                value={selectedClient}
                onChange={value => {
                  if (!value) {
                    setHasNoContracts(false);
                  }
                  setSelectedClient(value);
                }}
              />
              {hasNoContracts && (
                <div
                  style={{
                    marginTop: 10,
                    color: 'red',
                    fontSize: '0.88rem',
                    textAlign: 'left',
                  }}
                >
                  *&nbsp;
                  {t('clientWithoutContracts')}
                </div>
              )}
            </Col>
          </Row>
        ),
      },
      {
        title: t('attributes:contract'),
        disableNextButton: !virtualPlanId,
        loadingNextButton: virtualPlanId && !contractDetails,
        doNotShow: defaultContractId,
        content: (
          <Row gutter={[16, 16]} justify="center">
            <Col lg={16} xl={16} xs={24}>
              <LabelModel text={t('attributes:contract')} />
              <Select
                optionFilterProp={'label'}
                options={contractsByClientOptions}
                loading={!contractsByClientOptions}
                disabled={!contractsByClientOptions}
                placeholder={t('placeholders:contract')}
                style={{ width: '100%' }}
                value={virtualPlanId}
                allowClear
                showSearch
                onChange={setVirtualPlanId}
              />
            </Col>
          </Row>
        ),
      },
      {
        title: t('modal.stepsTitles.overflowRanges'),
        doNotShow: payPerUseFlag !== 'S',
        content: (
          <OverflowRangesConfig
            overflowRanges={overflowRanges}
            setOverflowRanges={setOverflowRanges}
            editable
          />
        ),
      },
      {
        title: t('modal.stepsTitles.difPerOperator'),
        doNotShow: operatorDiffChargeFlag !== 'S',
        content: (
          <DifPerOperatorConfig
            difPerOperator={difPerOperator}
            setDifPerOperator={setDifPerOperator}
            editable
          />
        ),
      },
      {
        title: t('modal.stepsTitles.config'),
        disableNextButton: !description || description === '',
        content: (
          <>
            <Row gutter={[16, 10]} style={{ marginBottom: 10 }}>
              <Col lg={24} xl={24} xs={24}>
                <LabelModel text={t('attributes:description')} isRequired />
                <Input
                  placeholder={t('placeholders:description')}
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  allowClear
                />
              </Col>
              <Col lg={12} xl={12} xs={24}>
                <LabelModel
                  text={
                    <>
                      {t('attributes:activationDate')}
                      <Tooltip title={t('activationDateInfo')}>
                        <Button
                          type="link"
                          size="small"
                          icon={<InfoCircleOutlined />}
                        />
                      </Tooltip>
                    </>
                  }
                />
                <ParametrizedDatePicker
                  style={{ width: '100%' }}
                  disabledDate={d => d && d > moment().endOf('day')}
                  allowClear
                  value={activationDate && moment(activationDate)}
                  onChange={value => {
                    if (value) {
                      value = value.toISOString().split('T')[0];
                    }
                    setActivationDate(value);
                  }}
                />
              </Col>
              <Col lg={12} xl={12} xs={24}>
                <LabelModel text={t('attributes:nbPreActivationDays')} />
                <Select
                  options={nbPreActivationDaysOptions}
                  style={{ width: '100%' }}
                  placeholder={t('placeholders:nbPreActivationDays')}
                  allowClear
                  showSearch
                  defaultValue={0}
                  value={nbPreActivationDays}
                  onChange={setNbPreActivationDays}
                />
              </Col>
              {nbPreActivationDays && nbPreActivationDays > 0 ? (
                <Col lg={12} xl={12} xs={24}>
                  <LabelModel text={t('attributes:minimumConsumption')} />
                  <Input
                    type="number"
                    onChange={({ target: { value } }) =>
                      setMinimumConsumption(oldConsumption => ({
                        ...oldConsumption,
                        value,
                      }))
                    }
                    min={1}
                    value={minimumConsumption?.value}
                    addonAfter={
                      <Select
                        defaultValue="B"
                        value={minimumConsumption?.type}
                        options={['', 'K', 'M'].map(item => ({
                          label: `${item}B`,
                          value: `${item}B`,
                        }))}
                        onChange={type =>
                          setMinimumConsumption(oldConsumption => ({
                            ...oldConsumption,
                            type,
                          }))
                        }
                      />
                    }
                    style={{ width: '100%' }}
                  />
                </Col>
              ) : undefined}
              <Col lg={12} xl={12} xs={24}>
                <LabelModel text={t('attributes:numberOfTradeInInvoices')} />
                <InputNumber
                  defaultValue={0}
                  min={0}
                  placeholder={t('placeholders:numberOfTradeInInvoices')}
                  step={1}
                  style={{ width: '100%' }}
                  value={tradeInMonths}
                  onChange={setTradeInMonths}
                />
              </Col>

              <Col lg={12} xl={12} xs={24}>
                <Checkbox
                  style={{ marginTop: nbPreActivationDays > 0 ? 16 : 40 }}
                  checked={blockedByDefault}
                  onChange={e => setBlockedByDefault(e.target.checked)}
                >
                  {t('attributes:blockedByDefault')}
                </Checkbox>
              </Col>
              {nbPreActivationDays > 0 && (
                <Col lg={12} xl={12} xs={24}>
                  <Checkbox
                    style={{
                      marginTop: 16,
                    }}
                    checked={chargeActivationFeeOnTransfer}
                    onChange={e =>
                      setChargeActivationFeeOnTransfer(e.target.checked)
                    }
                  >
                    {t('attributes:chargeActivationFeeOnTransfer')}
                    <Tooltip title={t('chargeActivationFeeOnTransferInfo')}>
                      <Button
                        type="link"
                        size="small"
                        icon={<InfoCircleOutlined />}
                      />
                    </Tooltip>
                  </Checkbox>
                </Col>
              )}
            </Row>

            <Row gutter={[16, 10]}>
              <Col span={24}>
                <LabelModel text={t('attributes:trackingNumbers')} />
                <TagInput
                  newButtonText={t('attributes:newCode')}
                  onChange={setTrackingNumbers}
                />
              </Col>
            </Row>
            {contractDetails?.franchise === 0 && (
              <Alert
                style={{ padding: 8, marginTop: 32 }}
                description={t('contracts:details.infoMessage')}
                type="warning"
                showIcon
              />
            )}

            <Divider
              style={{
                marginTop: 40,
                fontSize: '0.85rem',
                opacity: 0.7,
                textTransform: 'uppercase',
              }}
            >
              {t('contracts:details.preDefined')}
            </Divider>

            <Row
              gutter={[16, 10]}
              style={{
                marginBottom: 24,
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              <DescriptionModel
                label={t('attributes:franchise')}
                text={`${contractDetails?.franchise || 0} MB`}
              />

              {sharingType ? (
                <DescriptionModel
                  label={t('attributes:type.title')}
                  text={sharingType}
                />
              ) : undefined}

              {contractDetails?.motOperators ? (
                <DescriptionModel
                  label={t('attributes:operator')}
                  text={contractDetails.motOperators.name}
                />
              ) : undefined}
              {contractDetails?.planType ? (
                <DescriptionModel
                  label={t('contracts:newContract.planType')}
                  text={t(
                    `contracts:newContract.planTypes.${contractDetails?.planType?.toLowerCase()}`,
                  )}
                />
              ) : undefined}
            </Row>

            <Row gutter={[16, 10]} style={{ marginBottom: 10 }}>
              {operatorDiffChargeFlag === 'N' && (
                <Col lg={12} xl={12} xs={24}>
                  <LabelModel text={t('attributes:price')} />
                  <InputNumber
                    defaultValue={price}
                    formatter={value => `R$ ${value}`}
                    min={0}
                    parser={value => value.replace('R$ ', '')}
                    step={0.01}
                    style={{ width: '100%' }}
                    value={price}
                    onChange={value => setPrice(value)}
                  />
                </Col>
              )}
              <Col lg={12} xl={12} xs={24}>
                <LabelModel text={t('attributes:activationPrice')} />
                <InputNumber
                  defaultValue={activationPrice}
                  formatter={value => `R$ ${value}`}
                  min={0}
                  parser={value => value.replace('R$ ', '')}
                  step={0.01}
                  style={{ width: '100%' }}
                  value={activationPrice}
                  onChange={value => setActivationPrice(value)}
                />
              </Col>
              {payPerUseFlag === 'N' && (
                <Col lg={12} xl={12} xs={24}>
                  <LabelModel text={t('attributes:mbExcValue')} />
                  <InputNumber
                    value={megabyteExcPrice}
                    formatter={value => `R$ ${value}`}
                    min={0}
                    parser={value => value.replace('R$ ', '')}
                    step={0.01}
                    style={{ width: '100%' }}
                    onChange={value => setMegabyteExcPrice(value)}
                  />
                </Col>
              )}
              {contractDetails?.planType === 'ALLOWANCE' && (
                <>
                  <Col lg={12} xl={12} xs={24}>
                    <LabelModel
                      text={t('contracts:newContract.planValidity')}
                    />
                    <Select
                      value={planExpirationMonths}
                      onChange={setPlanExpirationMonths}
                      placeholder={t(
                        'contracts:newContract.selectPlanValidity',
                      )}
                      style={{ width: '100%' }}
                    >
                      <Option value={12}>12 {t('attributes:months')}</Option>
                      <Option value={24}>24 {t('attributes:months')}</Option>
                      <Option value={36}>36 {t('attributes:months')}</Option>
                    </Select>
                  </Col>
                  <Col lg={12} xl={12} xs={24}>
                    <Checkbox
                      style={{ marginTop: 35 }}
                      checked={automaticRenewal}
                      onChange={e => setAutomaticRenewal(e.target.checked)}
                    >
                      {t('contracts:newContract.autoRenewal')}
                    </Checkbox>
                  </Col>
                </>
              )}

              {contractDetails?.planType === 'BAIT' && (
                <>
                  {nbPreActivationDays > 0 && (
                    <Col lg={12} xl={12} xs={24}>
                      <Checkbox
                        style={{
                          marginTop: 16,
                        }}
                        checked={chargeIscaOnTransfer}
                        onChange={e =>
                          setChargeIscaOnTransfer(e.target.checked)
                        }
                      >
                        {t('attributes:chargeIscaOnTransfer')}
                        <Tooltip
                          title={t('attributes:chargeIscaOnTransferInfo')}
                        >
                          <Button
                            type="link"
                            size="small"
                            icon={<InfoCircleOutlined />}
                          />
                        </Tooltip>
                      </Checkbox>
                    </Col>
                  )}
                  <Col lg={12} xl={12} xs={24}>
                    <LabelModel
                      text={t('attributes:iscaDurationInDays')}
                      isRequired
                    />
                    <Select
                      style={{ width: '100%' }}
                      value={iscaDurationInDays}
                      onChange={setIscaDurationInDays}
                      allowClear
                    >
                      {[30, 60, 90].map(item => (
                        <Option value={item} key={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col lg={12} xl={12} xs={24}>
                    <LabelModel text={t('attributes:iscaMaxRenewal')} />
                    <InputNumber
                      defaultValue={2}
                      min={0}
                      step={1}
                      style={{ width: '100%' }}
                      value={iscaMaxRenewal}
                      onChange={setIscaMaxRenewal}
                    />
                  </Col>
                </>
              )}
              {contractDetails?.planType !== 'ALLOWANCE' && (
                <>
                  <Col lg={12} xl={12} xs={24}>
                    <LabelModel text={t('attributes:penaltyForCancellation')} />
                    <Select
                      options={penaltyForCancellationOptions}
                      style={{ width: '100%' }}
                      placeholder={t('placeholders:penaltyForCancellation')}
                      allowClear
                      showSearch
                      value={loyaltyType}
                      onChange={setLoyaltyType}
                    />
                  </Col>
                  {loyaltyType === 1 && (
                    <Col lg={12} xl={12} xs={24}>
                      <LabelModel text={t('attributes:penaltyAmount')} />
                      <InputNumber
                        defaultValue={0}
                        formatter={value => `R$ ${value}`}
                        min={0}
                        parser={value => value.replace('R$ ', '')}
                        step={0.01}
                        style={{ width: '100%' }}
                        onChange={setPenalty}
                        value={penalty}
                      />
                    </Col>
                  )}
                  {loyaltyType === 2 && (
                    <Col lg={12} xl={12} xs={24}>
                      <LabelModel
                        text={t('attributes:loyaltyTime')}
                        isRequired={true}
                      />
                      <Select
                        style={{ width: '100%' }}
                        value={loyaltyTime}
                        onChange={setLoyaltyTime}
                        allowClear
                      >
                        {[12, 24, 36].map(item => (
                          <Option value={item} key={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                  )}
                </>
              )}
            </Row>
            <Row gutter={[16, 10]}>
              <Col lg={12} xl={12} xs={24}>
                <Checkbox
                  style={{ marginTop: 16 }}
                  checked={automaticBlocking}
                  onChange={e => setAutomaticBlocking(e.target.checked)}
                >
                  {t('attributes:automaticBlocking.title')}
                </Checkbox>
              </Col>

              <Col lg={12} xl={12} xs={24}>
                <Checkbox
                  style={{ marginTop: 16 }}
                  checked={activeRoaming}
                  onChange={e => setActiveRoaming(e.target.checked)}
                >
                  {t('attributes:activeRoaming')}
                </Checkbox>
              </Col>
            </Row>
          </>
        ),
      },
    ],
    [
      t,
      selectedClient,
      contractsByClient,
      hasNoContracts,
      defaultCustomerId,
      virtualPlanId,
      contractDetails,
      defaultContractId,
      contractsByClientOptions,
      payPerUseFlag,
      overflowRanges,
      operatorDiffChargeFlag,
      difPerOperator,
      description,
      activationDate,
      nbPreActivationDaysOptions,
      nbPreActivationDays,
      minimumConsumption?.value,
      minimumConsumption?.type,
      tradeInMonths,
      blockedByDefault,
      chargeActivationFeeOnTransfer,
      sharingType,
      price,
      activationPrice,
      megabyteExcPrice,
      penaltyForCancellationOptions,
      loyaltyType,
      penalty,
      loyaltyTime,
      automaticBlocking,
      activeRoaming,
      automaticRenewal,
      planExpirationMonths,
      chargeIscaOnTransfer,
      iscaDurationInDays,
      iscaMaxRenewal,
    ],
  );

  return (
    <ModalOfSteps
      title={t('modal.title')}
      width="50%"
      handleConfirm={handleCreateRequest}
      visible={modalVisible}
      steps={newRequestSteps}
      onClose={() => cleanStateAndClose(true)}
      confirmLoading={confirmLoading}
      disableConfirmButton={
        !description ||
        description === '' ||
        (loyaltyType === 2 && !loyaltyTime)
      }
    />
  );
};

export default NewRequestModal;
