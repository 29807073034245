import React, { useMemo } from 'react';
import { Table as AntdTable, Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import useSwr from '@src/hooks/useSwr';
import { CSVLink } from 'react-csv';
import ActionTypes from '@src/utils/deviceActions';

const DevicesModal = ({ operationdId, visible, onClose, operationType }) => {
  const { t } = useTranslation('operational');
  const { t: tDevices } = useTranslation('device-actions');

  const { data } = useSwr(
    visible ? `/service-proxy/broker/operations/${operationdId}/devices` : null,
  );

  const actionsNumber = [
    {
      number: ActionTypes.ALLOWANCE_PLAN_RENEWAL,
      name: 'allowance_plan_renewal',
    },
    {
      number: ActionTypes.BAIT_RENEWAL,
      name: 'bait_renewal',
    },
    {
      number: ActionTypes.BROKER_CANCELLATION,
      name: 'cancellation',
    },
    {
      number: ActionTypes.BROKER_SUBSTITUTION,
      name: 'substitution',
    },
    {
      number: ActionTypes.SUSPENSION,
      name: 'suspension',
    },
    {
      number: ActionTypes.SUSPENSION_WITHDRAWAL,
      name: 'suspension_reverse',
    },
  ];

  const findNumber = actionsNumber.find(
    item => item.name === operationType?.toLowerCase(),
  )?.number;

  const devicesColumns = useMemo(
    () => [
      {
        title: 'MSISDN',
        dataIndex: 'msisdn',
      },
      {
        title: 'ICCID',
        dataIndex: 'iccd',
      },
      {
        title: t('table.status'),
        dataIndex: 'status',
        render: status => tDevices(`status.${findNumber}.${status}`),
      },
    ],
    [t, tDevices, findNumber],
  );

  const csvHeaders = [
    { label: 'MSISDN', key: 'msisdn' },
    { label: 'ICCID', key: 'iccd' },
    { label: `${t('table.status')}`, key: 'status' },
  ];

  return (
    <>
      <Modal
        bodyStyle={{ padding: 24 }}
        footer={false}
        visible={visible}
        centered
        title={'Simcards'}
        onCancel={onClose}
      >
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button type="primary" style={{ marginBottom: 16 }}>
            <CSVLink
              data={
                data?.map(item => {
                  return {
                    msisdn: item.msisdn,
                    iccd: `'${item.iccd}`,
                    status: tDevices(`status.${findNumber}.${item.status}`),
                  };
                }) || []
              }
              headers={csvHeaders}
              filename={`simcards_${operationdId}.xlsx`}
            >
              {t('export')}
            </CSVLink>
          </Button>
        </div>
        <AntdTable
          dataSource={data || []}
          columns={devicesColumns}
          loading={!data}
          pagination={false}
          style={{ width: '100%' }}
          scroll={{ y: 600 }}
        />
      </Modal>
    </>
  );
};

export default DevicesModal;
