import { Tag } from '@src/../node_modules/antd/lib/index';
import { getDateToBRPattern } from '@src/utils/formatters';
import { Card, Col, Row, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryItem } from './QueryItem';
import moment from 'moment';
import './styles.less';

export const QueryBuilder = ({
  clearSearchParams,
  setSearchParams,
  extraFilter,
  itemsToMap,
  defaultValues,
  handleChange,
  operatorsData,
}) => {
  const { lg, xl, xs } = {
    lg: 10,
    xl: 8,
    xs: 24,
  };
  const [itemsToCreateFilters, setItemsToCreateFilters] = useState(itemsToMap);

  const { t: translate } = useTranslation('inventory');
  const sliceTheTitle = (itemName, itemValue, date, range, defaultName) => {
    if (range) {
      const startValue =
        Boolean(itemValue?.split(' ')[0]) && itemValue?.split(' ')[0];
      const endValue =
        Boolean(itemValue?.split(' ')[1]) && itemValue?.split(' ')[1];
      return (
        <>
          {' '}
          {`${itemName}: `}
          {startValue && startValue}
          {startValue && endValue && ' - '}
          {endValue && endValue}
        </>
      );
    }
    if (defaultName === 'lastAlertDateFrom') {
      const dateValue = moment(itemValue).format('DD/MM/YYYY HH:mm');
      return (
        <>
          {' '}
          {`${itemName}: `}
          {dateValue && dateValue}
        </>
      );
    }
    if (date) {
      const startDate =
        Boolean(itemValue?.split(' ')[0]) &&
        getDateToBRPattern(itemValue.split(' ')[0]);

      const endDate =
        Boolean(itemValue?.split(' ')[1]) &&
        getDateToBRPattern(itemValue.split(' ')[1]);

      return (
        <>
          {' '}
          {`${itemName}: `}
          {startDate && startDate}
          {startDate && endDate && ' - '}
          {endDate && endDate}
        </>
      );
    }

    return itemValue?.length > 32 ? (
      <Tooltip title={itemValue}>
        {' '}
        {`${itemName} :  ${itemValue?.slice(0, 32)}...`}
      </Tooltip>
    ) : (
      <>
        {' '}
        {`${itemName}: `}
        {itemValue}
      </>
    );
  };

  const findValueToShow = item => {
    if (item.options && item.name === 'planTypes') {
      const itemsToFindDefaultValue = item.options;
      const allValues = item.defaultValue.split(',');
      const valuesToShow = allValues.map(value => {
        return itemsToFindDefaultValue.find(
          optionsItem => optionsItem.value == value,
        )?.label;
      });
      return valuesToShow.join(', ');
    }

    if (item.options && item.name !== 'lastAlertDateFrom') {
      const itemsToFindDefaultValue =
        item?.name === 'customerName'
          ? item?.options?.map(itemToReturn => {
              return {
                value: itemToReturn.id || item.value,
                label: itemToReturn.name || item.label,
              };
            })
          : item.options;
      return itemsToFindDefaultValue.find(
        optionsItem =>
          optionsItem.value == item.defaultValue ||
          optionsItem.id == item.defaultValue,
      )?.label;
    }

    return item.defaultValue;
  };
  useEffect(() => {
    const existSubOperator = itemsToMap.find(
      item => item?.name === 'subOperator',
    );
    if (operatorsData && existSubOperator) {
      const newData = itemsToMap?.map(item => {
        if (item.name === 'subOperator') {
          return {
            ...item,
            options: operatorsData?.map(({ name }) => ({
              label: name,
              value: name,
            })),
          };
        }
        return item;
      });
      setItemsToCreateFilters(newData);
      return;
    }
    if (itemsToMap) {
      setItemsToCreateFilters(itemsToMap);
    }
  }, [itemsToMap, operatorsData]);

  return (
    <Card className={`filters-card`}>
      {extraFilter && extraFilter}
      <div className="query-builder-items-container">
        <div className="query-builder-items-column">
          <div className="query-builder-items-space" />
          {defaultValues && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 16,
              }}
            >
              <div>
                {defaultValues.map(
                  item =>
                    item &&
                    item.defaultValue && (
                      <Tag
                        closable
                        onClose={_ => {
                          if (item.range) {
                            clearSearchParams([
                              `${item?.range?.from}`,
                              `${item?.range?.to}`,
                            ]);
                          } else if (item.date) {
                            clearSearchParams([
                              `${item?.date?.start}`,
                              `${item?.date?.end}`,
                            ]);
                          } else {
                            clearSearchParams([item.value]);
                          }

                          handleChange(item.name, false);
                        }}
                        color="var(--primary-color)"
                        key={item.value}
                      >
                        {sliceTheTitle(
                          translate(`table.${item.name}`),
                          findValueToShow(item),
                          Boolean(item.date),
                          Boolean(item.range),
                          item.name,
                        )}
                      </Tag>
                    ),
                )}
              </div>
            </div>
          )}
          <Row
            align="bottom"
            className="query-builder-items-row"
            gutter={[16, 16]}
          >
            {itemsToCreateFilters?.map(
              item =>
                defaultValues.find(
                  itemInDefaultValues => itemInDefaultValues.name === item.name,
                )?.visible && (
                  <Col lg={lg} xl={xl} xs={xs} key={item.name}>
                    <QueryItem
                      setSearchParams={setSearchParams}
                      clearSearchParams={clearSearchParams}
                      valueOfItem={item}
                    />
                  </Col>
                ),
            )}
          </Row>
        </div>
      </div>
    </Card>
  );
};
