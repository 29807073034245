import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@components/ui';

import { Statistic, Progress, Empty } from 'antd';
import { formatBRNumber } from '@utils/formatters';
import useSwr from '@hooks/useSwr';
import { useNavigate } from 'react-router-dom';
import { getPercentage } from '@utils/getPercentage';
import { green, gold, red } from '@ant-design/colors';
import moment from 'moment';

const getColor = percentage => {
  if (percentage < 50) {
    return red[4];
  }
  if (percentage < 70) {
    return gold[5];
  }
  return green[5];
};

const LinesCloseToExpire = ({ filters }) => {
  const { t } = useTranslation(['dashboard', 'inventory']);
  const navigate = useNavigate();

  const { data } = useSwr(
    `/service-proxy/inventory/MOT/dashboards/expiring-simcards?daysToExpire=15`,
    {
      ...filters,
    },
    {
      method: 'post',
    },
  );

  const currentDatePlus15 = moment().add(15, 'days').format('YYYYMMDD');

  const percentage = useMemo(
    () => (data ? getPercentage(data?.matching, data.total) : 0),
    [data],
  );

  const handleCardClick = () => {
    navigate(
      `/management/inventory?showContracted=${filters.showContracted}&planTypes=BAIT%2CALLOWANCE&cancellationDateTo=${currentDatePlus15}`,
    );
  };

  return (
    <Card loading={!data} className="card-click-filter">
      <div onClick={handleCardClick}>
        {data?.length === 0 || data?.total === 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <>
            <Statistic
              precision={0}
              suffix={`/ ${formatBRNumber(data?.total)}`}
              title={t('lines-close-to-expire')}
              value={data?.matching}
              valueStyle={{
                color: getColor(percentage),
              }}
              formatter={value => {
                return formatBRNumber(value);
              }}
            />
            <Progress
              percent={percentage}
              showInfo={false}
              strokeColor={getColor(percentage)}
            />
          </>
        )}
      </div>
    </Card>
  );
};

export default LinesCloseToExpire;
