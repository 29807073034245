import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import './styles.less';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Select,
  Button,
  Space,
  Row,
  Tag,
  Tabs,
  Badge,
  Typography,
  Tooltip,
  Progress,
  Divider,
  List,
} from 'antd';
import TableMenu from './TableMenu/TableMenu';
import {
  ControlOutlined,
  PlusCircleOutlined,
  FileSearchOutlined,
  ExclamationCircleOutlined,
  WarningFilled,
} from '@ant-design/icons';
import { FiExternalLink } from 'react-icons/fi';
import Table from '@components/Table';
import RoleChecker from '@components/RoleChecker/RoleChecker';
import KeycloakContext from '@store/KeycloakContext/KeycloakContext';
import roles from '@utils/rolesConstants';
import DeviceActionModal from '@components/DeviceActionModal';
import DeviceActionsMenu from '@components/DeviceActionsMenu';
import ActionTypes from '@utils/deviceActions';
import useSwr from '@hooks/useSwr';
import ProductTypes from '@utils/productTypes';
import { Filters } from '@components/Filters';
import {
  dateFormat,
  bytesFormat,
  getDateWithISOFormat,
  formatCurrency,
  extractDateInISOFormat,
  getDateFromPartiallyInIsoFormat,
} from '@utils/formatters';
import InventoryPreview from './InventoryPreview/InventoryPreview';
import useSearchParams from '@src/hooks/useSearchParams';
import { useTranslation } from 'react-i18next';
import CustomPopover from '@src/components/CustomPopover/CustomPopover';
import { RiCalendarTodoFill } from 'react-icons/ri';
import SessionContext from '@src/store/SessionContext/SessionContext';
import BlockStatusTag from '@src/components/BlockStatusTag/BlockStatusTag';
import RequestReport from '@src/components/RequestReport/RequestReport';
import { QueryBuilder } from '@src/components/QueryBuilder/index';
import { getSearchParam } from '@src/utils/manageSearchParams';
import Widgets from './Widgets/Widgets';
import moment from 'moment';

const { TabPane } = Tabs;

const { Text } = Typography;

const getStatus = t => [
  { label: t('status:ACTIVE'), value: 'ACTIVE' },
  { label: t('status:CANCELED'), value: 'CANCELED' },
  { label: t('status:BLOCKED'), value: 'BLOCKED' },
  { label: t('status:SUSPENDED'), value: 'SUSPENDED' },
  { label: t('status:WAITING_CANCELLATION'), value: 'WAITING_CANCELLATION' },
  { label: t('status:WAITING_SUBSTITUTION'), value: 'WAITING_SUBSTITUTION' },
  {
    label: t('status:WAITING_SUSPENSION'),
    value: 'WAITING_SUSPENSION',
  },
  {
    label: t('status:WAITING_REACTIVATION'),
    value: 'WAITING_REACTIVATION',
  },
  { label: t('status:TRADE_IN'), value: 'TRADE_IN' },
  { label: t('status:PRE_ACTIVE'), value: 'PRE_ACTIVE' },
  { label: t('status:RENOVACAO_EM_ANALISE'), value: 'RENOVACAO_EM_ANALISE' },
  { label: t('status:RENOVACAO_AGENDADA'), value: 'RENOVACAO_AGENDADA' },
  {
    label: t('status:WAITING_RENEWAL'),
    value: 'WAITING_RENEWAL',
  },
];

const getStock = t => [
  { label: t('stock-values.all'), value: null },
  { label: t('stock-values.inStock'), value: 'true' },
  { label: t('stock-values.allocated'), value: 'false' },
];

const getStatusBlock = t => [
  { label: t('lock-status.types.no-lock'), value: 0 },
  { label: t('lock-status.types.permanent'), value: 1 },
  { label: t('lock-status.types.until-renovation'), value: 2 },
  { label: t('lock-status.types.temporary'), value: 3 },
  { label: t('lock-status.types.financial'), value: 4 },
  { label: t('lock-status.types.temporary-suspension'), value: 5 },
  { label: t('lock-status.types.cancellation'), value: 6 },
  { label: t('lock-status.types.substitution'), value: 8 },
  { label: t('lock-status.types.quarantine'), value: 7 },
];

const getLastAlert = t => [
  {
    label: t('attributes:lastAlertDateFrom.lastOneHour'),
    value: moment().subtract(1, 'hours').format(),
  },
  {
    label: t('attributes:lastAlertDateFrom.lastThreeHours'),
    value: moment().subtract(3, 'hours').format(),
  },
  {
    label: t('attributes:lastAlertDateFrom.lastSixHours'),
    value: moment().subtract(6, 'hours').format(),
  },
  {
    label: t('attributes:lastAlertDateFrom.lastTwelveHours'),
    value: moment().subtract(12, 'hours').format(),
  },
  {
    label: t('attributes:lastAlertDateFrom.lastDay'),
    value: moment().subtract(24, 'hours').format(),
  },
  {
    label: t('attributes:lastAlertDateFrom.lastWeek'),
    value: moment().subtract(7, 'days').format(),
  },
  {
    label: t('attributes:lastAlertDateFrom.lastMonth'),
    value: moment().subtract(1, 'months').format(),
  },
];

const getAutomaticBlocking = t => [
  { label: t('attributes:automaticBlocking.activated'), value: 'true' },
  { label: t('attributes:automaticBlocking.disabled'), value: 'false' },
];

const getHasTag = t => [
  { label: t('attributes:hasTag.yes'), value: 'true' },
  { label: t('attributes:hasTag.no'), value: 'false' },
];

const technologyOptions = [
  { label: '2G', value: '2G' },
  { label: '3G', value: '3G' },
  { label: '4G', value: '4G' },
];

const DEFAULT_PAGINATION = {
  pageSize: 20,
  linesPerPage: 20,
  pageSizeOptions: [
    {
      value: 20,
    },
    {
      value: 40,
    },
    {
      value: 80,
    },
    {
      value: 120,
    },
  ],
  total: 0,
};

// isInTheRequestDetails = inventory displayed in an order details
const Inventory = ({
  isInTheRequestDetails,
  defaultFilters,
  defaultColumns,
}) => {
  const { t } = useTranslation([
    'inventory',
    'status',
    'requests',
    'attributes',
  ]);
  const { keycloak } = useContext(KeycloakContext);
  const {
    userRoles,
    userPreferences: { getTablePreferences },
    customer: { customerLogged, isCustomerFinanciallyBlocked, customersData },
    hasCustomersRoles,
  } = useContext(SessionContext);

  const hasRole = useCallback(
    (roleToCheck, client) => userRoles?.[client]?.includes(roleToCheck),
    [userRoles],
  );

  const customerId = keycloak?.idTokenParsed?.customerId;
  const customerType = keycloak?.idTokenParsed?.customerType;
  const isEndCustomer = customerType === 'CUSTOMER';
  const navigate = useNavigate();
  const isAllcom = customerLogged?.id === 10000;
  const canSeeSuspensionAction = !isAllcom;
  const [stock] = useState(getStock(t));
  const [selectedStock, setSelectedStock] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalDeviceActionsVisible, setModalDeviceActionsVisible] =
    useState(false);
  const [actionToExecute, setActionToExecute] = useState(-1);
  const [showFilters, setShowFilters] = useState(false);
  const [previewDevice, setPreviewDevice] = useState();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [showContracted, setShowContracted] = useState();
  const [connectedOperator, setConnectedOperator] = useState();
  const [subOperator, setSubOperator] = useState();
  const [online, setOnline] = useState();
  const [lastConnectionFrom, setLastConnectionFrom] = useState();
  const [lastConnectionTo, setLastConnectionTo] = useState();
  const [consumptionFrom, setConsumptionFrom] = useState();
  const [consumptionTo, setConsumptionTo] = useState();
  const [customer, setCustomer] = useState();
  const [automaticBlocking, setAutomaticBlocking] = useState();
  const [msisdn, setMsisdn] = useState();
  const [imsi, setImsi] = useState();
  const [operator, setOperator] = useState();
  const [iccid, setIccid] = useState();
  const [technology, setTechnology] = useState();
  const [apn, setApn] = useState();
  const [provisionedApn, setProvisionedApn] = useState();
  const [activationDateFrom, setActivationDateFrom] = useState();
  const [activationDateTo, setActivationDateTo] = useState();
  const [franchise, setFranchise] = useState();
  const [nickname, setNickname] = useState();
  const [customerDocumentNumber, setCustomerDocumentNumber] = useState();
  const [imei, setImei] = useState();
  const [imeiLocked, setImeiLocked] = useState();
  const [contractAssetNumber, setContractAssetNumber] = useState();
  const [observation, setObservation] = useState();
  const [status, setStatus] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [postalCode, setPostalCode] = useState();
  const [consumptionPercentFrom, setConsumptionPercentFrom] = useState();
  const [consumptionPercentTo, setConsumptionPercentTo] = useState();
  const [networkBlockStatus, setNetworkBlockStatus] = useState();
  const [blockedByDefault, setBlockedByDefault] = useState();
  const [accountDesc, setAccountDesc] = useState();
  const [accountId, setAccountId] = useState();
  const [plan, setPlan] = useState();
  const [loyaltyType, setLoyaltyType] = useState();
  const [loyaltyTime, setLoyaltyTime] = useState();
  const [cancellationFeeFrom, setCancellationFeeFrom] = useState();
  const [cancellationFeeTo, setCancellationFeeTo] = useState();
  const [activationPriceFrom, setActivationPriceFrom] = useState();
  const [activationPriceTo, setActivationPriceTo] = useState();
  const [priceFrom, setPriceFrom] = useState();
  const [priceTo, setPriceTo] = useState();
  const [megabyteExcPriceFrom, setMegabyteExcPriceFrom] = useState();
  const [megabyteExcPriceTo, setMegabyteExcPriceTo] = useState();
  const [simcardCreditFrom, setSimcardCreditFrom] = useState();
  const [simcardCreditTo, setSimcardCreditTo] = useState();
  const [tradeInMonths, setTradeInMonths] = useState();
  const [preActivationDays, setPreActivationDays] = useState();
  const [preActivationEndDateFrom, setPreActivationEndDateFrom] = useState();
  const [preActivationEndDateTo, setPreActivationEndDateTo] = useState();
  const [sharingType, setSharingType] = useState();
  const [brokerContractId, setBrokerContractId] = useState();
  const [brokerContractDesc, setBrokerContractDesc] = useState();
  const [sharedContractCreditFrom, setSharedContractCreditFrom] = useState();
  const [sharedContractCreditTo, setSharedContractCreditTo] = useState();
  const [sharedContractFranchiseFrom, setSharedContractFranchiseFrom] =
    useState();
  const [sharedContractFranchiseTo, setSharedContractFranchiseTo] = useState();
  const [hostname, setHostname] = useState();
  const [cancellationDateFrom, setCancellationDateFrom] = useState();
  const [cancellationDateTo, setCancellationDateTo] = useState();
  const [suspensionEligibility, setSuspensionEligibility] = useState();
  const [suspensionStartDateFrom, setSuspensionStartDateFrom] = useState();
  const [suspensionStartDateTo, setSuspensionStartDateTo] = useState();
  const [suspensionEndDateFrom, setSuspensionEndDateFrom] = useState();
  const [suspensionEndDateTo, setSuspensionEndDateTo] = useState();
  const [lastAlertDateFrom, setLastAlertDateFrom] = useState();
  const [planTypes, setPlanTypes] = useState();

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);

  const handleShowDrawer = record => {
    setPreviewDevice(record);
    setDrawerVisible(true);
  };

  const { data: contracts } = useSwr('/service-proxy/contracts/types');
  const { data: contractsData } = useSwr('/service-proxy/contracts', {
    product: 'MOT',
  });
  const { data: operatorsData } = useSwr('/service-proxy/mot-operators');

  const operators = useMemo(() => {
    const allOperators = [
      'ALGAR TELECOM',
      'ARQIA',
      'CLARO',
      'OI',
      'TIM',
      'VIVO',
      'SIERRA',
    ];

    return allOperators.map(name => ({
      label: name,
      value: name,
    }));
  }, []);

  const hasInventoryRole = useCallback(
    roleToCheck => userRoles?.inventory?.includes(roleToCheck),
    [userRoles],
  );
  const operatorsResponse = useMemo(
    () => operatorsData?.map(({ name }) => ({ label: name, value: name })),
    [operatorsData],
  );
  const paramsAttributes = useMemo(
    () => [
      ...(showContracted === 'false' || !showContracted || !isAllcom
        ? [
            // {
            //   name: 'loyaltyType',
            //   setState: setLoyaltyType,
            //   inTheFilters: true,
            //   rules: ['Options'],
            //   options: [
            //     {
            //       label: t('typesOfPenalty.0'),
            //       value: '0',
            //     },
            //     {
            //       label: t('typesOfPenalty.1'),
            //       value: '1',
            //     },
            //     {
            //       label: t('typesOfPenalty.2'),
            //       value: '2',
            //     },
            //   ],
            //   visible: hasInventoryRole(roles.INVENT_LOYALTY_TYPE),
            //   category: 'contract',
            // },
            // {
            //   name: 'loyaltyTime',
            //   setState: setLoyaltyTime,
            //   inTheFilters: true,
            //   rules: ['Equals'],
            //   visible: hasInventoryRole(roles.INVENT_LOYALTY_TIME),
            //   category: 'contract',
            // },
            // {
            //   name: 'accountDesc',
            //   setState: setAccountDesc,
            //   inTheFilters: true,
            //   rules: ['Equals'],
            //   visible: hasInventoryRole(roles.INVENT_ACCOUNT_DESC),
            //   category: 'contract',
            // },
            // {
            //   name: 'accountId',
            //   setState: setAccountId,
            //   inTheFilters: true,
            //   rules: ['Equals'],
            //   visible: hasInventoryRole(roles.INVENT_ACCOUNT_ID),
            //   category: 'contract',
            // },
            // {
            //   name: 'tradeInMonths',
            //   setState: setTradeInMonths,
            //   inTheFilters: true,
            //   rules: ['Equals'],
            //   visible: hasInventoryRole(roles.INVENT_TRADE_IN_MONTHS),
            //   category: 'contract',
            // },
            // {
            //   name: 'brokerContractId',
            //   setState: setBrokerContractId,
            //   inTheFilters: true,
            //   rules: ['Equals'],
            //   visible: hasInventoryRole(roles.INVENT_BROKER_CONTRACT_ID),
            //   category: 'contract',
            // },
            // {
            //   name: 'brokerContractDesc',
            //   setState: setBrokerContractDesc,
            //   inTheFilters: true,
            //   rules: ['Equals'],
            //   visible: hasInventoryRole(roles.INVENT_BROKER_CONTRACT_DESC),
            //   category: 'contract',
            // },
            // {
            //   name: 'preActivationDays',
            //   setState: setPreActivationDays,
            //   inTheFilters: true,
            //   rules: ['Options'],
            //   options: [
            //     {
            //       value: 1,
            //       label: `1 ${t('days')}`,
            //     },
            //     {
            //       value: 2,
            //       label: `2 ${t('days')}`,
            //     },
            //     {
            //       value: 3,
            //       label: `3 ${t('days')}`,
            //     },
            //     {
            //       value: 4,
            //       label: `4 ${t('days')}`,
            //     },
            //     {
            //       value: 5,
            //       label: `5 ${t('days')}`,
            //     },
            //     {
            //       value: 6,
            //       label: `6 ${t('days')}`,
            //     },
            //     {
            //       value: 7,
            //       label: `7 ${t('days')}`,
            //     },
            //     {
            //       value: 8,
            //       label: `8 ${t('days')}`,
            //     },
            //     {
            //       value: 9,
            //       label: `9 ${t('days')}`,
            //     },
            //     {
            //       value: 10,
            //       label: `10 ${t('days')}`,
            //     },
            //     {
            //       value: 15,
            //       label: `15 ${t('days')}`,
            //     },
            //     {
            //       value: 20,
            //       label: `20 ${t('days')}`,
            //     },
            //     {
            //       value: 30,
            //       label: `40 ${t('days')}`,
            //     },
            //     {
            //       value: 45,
            //       label: `45 ${t('days')}`,
            //     },
            //     {
            //       value: 60,
            //       label: `60 ${t('days')}`,
            //     },
            //     {
            //       value: 90,
            //       label: `90 ${t('days')}`,
            //     },
            //   ],
            //   visible: hasInventoryRole(roles.INVENT_PRE_ACTIVATION_DAYS),
            //   category: 'contract',
            // },
            // {
            //   name: 'blockedByDefault',
            //   setState: setBlockedByDefault,
            //   rules: ['Options'],
            //   options: [
            //     {
            //       label: t('blocked.block'),
            //       value: true,
            //     },
            //     {
            //       label: t('blocked.unblock'),
            //       value: false,
            //     },
            //   ],
            //   inTheFilters: true,
            //   visible: hasInventoryRole(roles.INVENT_BLOCKED_BY_DEFAULT),
            //   category: 'contract',
            // },
            // {
            //   name: 'sharingType',
            //   setState: setSharingType,
            //   rules: ['Options'],
            //   options: [
            //     {
            //       label: t('sharingType.TOTAL'),
            //       value: 'TOTAL',
            //     },
            //     {
            //       label: t('sharingType.NONE'),
            //       value: 'NONE',
            //     },
            //     // {
            //     //   label: t('sharingType.MIXED'),
            //     //   value: 'MIXED',
            //     // },
            //   ],
            //   visible: hasInventoryRole(roles.INVENT_SHARING_TYPE),
            //   inTheFilters: true,
            //   category: 'contract',
            // },
            {
              name: 'priceFrom',
              setState: setPriceFrom,
              rules: ['RangeValues', 'R$'],
              range: {
                from: 'priceFrom',
                to: 'priceTo',
              },
              visible: hasInventoryRole(roles.INVENT_PRICE),
              inTheFilters: true,
              category: 'range',
            },
            {
              name: 'priceTo',
              setState: setPriceTo,
              inTheFilters: false,
            },
            // {
            //   name: 'activation-price',
            //   setState: setActivationPriceFrom,
            //   rules: ['RangeValues', 'R$'],
            //   range: {
            //     from: 'activationPriceFrom',
            //     to: 'activationPriceTo',
            //   },
            //   visible: hasInventoryRole(roles.INVENT_ACTIVATION_PRICE),
            //   inTheFilters: true,
            //   category: 'range',
            // },
            // {
            //   name: 'activationPriceTo',
            //   setState: setActivationPriceTo,
            //   inTheFilters: false,
            // },
            // {
            //   name: 'megabyteExcPrice',
            //   setState: setMegabyteExcPriceFrom,
            //   rules: ['RangeValues', 'R$'],
            //   range: {
            //     from: 'megabyteExcPriceFrom',
            //     to: 'megabyteExcPriceTo',
            //   },
            //   visible: hasInventoryRole(roles.INVENT_MEGABYTE_EXC_PRICE),
            //   inTheFilters: true,
            //   category: 'range',
            // },
            // {
            //   name: 'megabyteExcPriceTo',
            //   setState: setMegabyteExcPriceTo,
            //   inTheFilters: false,
            // },
            // {
            //   name: 'simcardCredit',
            //   setState: setSimcardCreditFrom,
            //   inTheFilters: true,
            //   rules: ['RangeValues', 'MB'],
            //   range: {
            //     from: 'simcardCreditFrom',
            //     to: 'simcardCreditTo',
            //   },
            //   visible: hasInventoryRole(roles.INVENT_SIMCARD_CREDIT),
            //   category: 'range',
            // },
            // {
            //   name: 'simcardCreditTo',
            //   setState: setSimcardCreditTo,
            //   inTheFilters: false,
            // },
            // {
            //   name: 'sharedContractFranchiseFrom',
            //   setState: setSharedContractFranchiseFrom,
            //   inTheFilters: true,
            //   rules: ['RangeValues', 'MB'],
            //   range: {
            //     from: 'sharedContractFranchiseFrom',
            //     to: 'sharedContractFranchiseTo',
            //   },
            //   visible: hasInventoryRole(roles.INVENT_SHARED_CONTRACT_FRANCHISE),
            //   category: 'range',
            // },
            // {
            //   name: 'sharedContractFranchiseTo',
            //   setState: setSharedContractFranchiseTo,
            //   inTheFilters: false,
            // },
            // {
            //   name: 'sharedContractCredit',
            //   setState: setSharedContractCreditFrom,
            //   inTheFilters: true,
            //   rules: ['RangeValues', 'MB'],
            //   range: {
            //     from: 'sharedContractCreditFrom',
            //     to: 'sharedContractCreditTo',
            //   },
            //   visible: hasInventoryRole(roles.INVENT_SHARED_CONTRACT_CREDIT),
            //   category: 'range',
            // },
            // {
            //   name: 'sharedContractCreditTo',
            //   setState: setSharedContractCreditTo,
            //   inTheFilters: false,
            // },
            // {
            //   name: 'preActivationEndDateFrom',
            //   setState: setPreActivationEndDateFrom,
            //   inTheFilters: true,
            //   rules: ['DateRange'],
            //   date: {
            //     start: 'preActivationEndDateFrom',
            //     end: 'preActivationEndDateTo',
            //   },
            //   visible: hasInventoryRole(roles.INVENT_PRE_ACTIVATION_END),
            //   category: 'date',
            // },
            // {
            //   name: 'preActivationEndDateTo',
            //   setState: setPreActivationEndDateTo,
            //   inTheFilters: false,
            // },
            // {
            //   name: 'cancellationFee',
            //   setState: setCancellationFeeFrom,
            //   inTheFilters: true,
            //   rules: ['RangeValues', 'R$'],
            //   range: {
            //     from: 'cancellationFeeFrom',
            //     to: 'cancellationFeeTo',
            //   },
            //   visible: hasInventoryRole(roles.INVENT_CANCELATION_FEE),
            //   category: 'contract',
            // },
            // {
            //   name: 'cancellationFeeTo',
            //   setState: setCancellationFeeTo,
            //   inTheFilters: false,
            // },
            {
              name: 'activationDateFrom',
              setState: setActivationDateFrom,
              inTheFilters: true,
              rules: ['DateRange'],
              date: {
                start: 'activationDateFrom',
                end: 'activationDateTo',
              },
              visible: hasInventoryRole(roles.INVENT_ACTIVATION_DATE),
              category: 'date',
            },
            {
              name: 'activationDateTo',
              setState: setActivationDateTo,
              inTheFilters: false,
            },
            {
              name: 'automaticBlocking',
              setState: setAutomaticBlocking,
              inTheFilters: true,
              rules: ['Options'],
              options: getAutomaticBlocking(t).map(({ label, value }) => ({
                label,
                value,
              })),
              visible: hasInventoryRole(roles.INVENT_AUTOMATIC_BLOCKING),
              category: 'contract',
            },
            {
              name: 'cancellationDateFrom',
              setState: setCancellationDateFrom,
              inTheFilters: true,
              rules: ['DateRange'],
              date: {
                start: 'cancellationDateFrom',
                end: 'cancellationDateTo',
              },
              visible: hasInventoryRole(roles.INVENT_CANCELATION_DATE),
              category: 'date',
              disableEndDateLimit: true,
            },
            {
              name: 'cancellationDateTo',
              setState: setCancellationDateTo,
              inTheFilters: false,
            },
            {
              name: 'franchise',
              setState: setFranchise,
              inTheFilters: true,
              visible: hasInventoryRole(roles.INVENT_FRANCHISE),
              rules: ['Equals'],
              category: 'details',
            },
            // {
            //   name: 'suspensionEligibility',
            //   setState: setSuspensionEligibility,
            //   rules: ['Options'],
            //   options: [
            //     {
            //       label: t('suspensionEligibility.eligible'),
            //       value: 'true',
            //     },
            //     {
            //       label: t('suspensionEligibility.not-eligible'),
            //       value: 'false',
            //     },
            //   ],
            //   visible: hasInventoryRole(roles.INVENT_SUSPENSION_ELIGIBILITY),
            //   category: 'contract',
            // },
            // {
            //   name: 'suspensionStartDateFrom',
            //   setState: setSuspensionStartDateFrom,
            //   inTheFilters: true,
            //   rules: ['DateRange'],
            //   date: {
            //     start: 'suspensionStartDateFrom',
            //     end: 'suspensionStartDateTo',
            //   },
            //   visible: hasInventoryRole(roles.INVENT_SUSPENSION_START_DATE),
            //   category: 'date',
            // },
            // {
            //   name: 'suspensionStartDateTo',
            //   setState: setSuspensionStartDateTo,
            //   inTheFilters: false,
            // },
            // {
            //   name: 'suspensionEndDateFrom',
            //   setState: setSuspensionEndDateFrom,
            //   inTheFilters: true,
            //   rules: ['DateRange'],
            //   date: {
            //     start: 'suspensionEndDateFrom',
            //     end: 'suspensionEndDateTo',
            //   },
            //   visible: hasInventoryRole(roles.INVENT_SUSPENSION_FINAL_DATE),
            //   category: 'date',
            // },
            // {
            //   name: 'suspensionEndDateTo',
            //   setState: setSuspensionEndDateTo,
            //   inTheFilters: false,
            // },
          ]
        : []),
      ...(isAllcom && showContracted === 'true'
        ? [
            // {
            //   name: 'plan',
            //   setState: setPlan,
            //   inTheFilters: true,
            //   visible: hasInventoryRole(roles.INVENT_PLAN),
            //   rules: ['Equals'],
            //   category: 'details',
            // },
            // {
            //   name: 'contractAssetNumber',
            //   setState: setContractAssetNumber,
            //   inTheFilters: true,
            //   visible: hasInventoryRole(roles.INVENT_CONTRACT_ASSET_NUMBER),
            //   rules: ['Equals'],
            //   category: 'contract',
            // },
          ]
        : []),
      {
        name: 'planTypes',
        rules: ['Options', 'Multiple'],
        options: [
          {
            label: t('planTypes.BAIT'),
            value: 'BAIT',
          },
          {
            label: t('planTypes.STANDARD'),
            value: 'STANDARD',
          },
          {
            label: t('planTypes.ALLOWANCE'),
            value: 'ALLOWANCE',
          },
        ],
        setState: setPlanTypes,
        inTheFilters: true,
        visible:
          hasInventoryRole(roles.INVENT_PLANTYPE) &&
          !(showContracted === 'true' && !isAllcom),
        category: 'details',
      },
      {
        name: 'stock',
        setState: setSelectedStock,
        inTheFilters: false,
      },
      {
        name: 'customerName',
        setState: setCustomer,
        inTheFilters: true,
        rules: ['Options'],
        options: customersData,
        visible:
          selectedStock !== 'true' &&
          hasInventoryRole(roles.INVENT_CUSTOMER_NAME) &&
          hasCustomersRoles,
        category: 'details',
      },
      {
        name: 'showContracted',
        setState: setShowContracted,
        inTheFilters: false,
      },
      {
        name: 'connectedOperator',
        setState: setConnectedOperator,
        inTheFilters: true,
        rules: ['Options'],
        options: operators ?? [],
        visible: hasInventoryRole(roles.INVENT_CONN_OPERATOR),
        category: 'details',
      },
      {
        name: 'operator',
        setState: setOperator,
        inTheFilters: true,
        rules: ['Options'],
        options: operators ?? [],
        visible: hasInventoryRole(roles.INVENT_OPERATOR),
        category: 'details',
      },
      {
        name: 'subOperator',
        setState: setSubOperator,
        inTheFilters: true,
        rules: ['Options'],
        options: operatorsResponse ?? [],
        visible: hasInventoryRole(roles.INVENT_SUB_OPERATOR),
        category: 'details',
      },
      {
        name: 'online',
        setState: setOnline,
        inTheFilters: true,
        rules: ['Options'],
        options: [
          {
            label: 'Online',
            value: 'true',
          },
          {
            label: 'Offline',
            value: 'false',
          },
        ],
        visible: hasInventoryRole(roles.INVENT_ONLINE),
        category: 'details',
      },
      {
        name: 'lastConnectionFrom',
        setState: setLastConnectionFrom,
        inTheFilters: true,
        rules: ['DateRange'],
        date: {
          start: 'lastConnectionFrom',
          end: 'lastConnectionTo',
        },
        visible: hasInventoryRole(roles.INVENT_LAST_CONNECTION),
        category: 'date',
      },
      {
        name: 'lastConnectionTo',
        setState: setLastConnectionTo,
        inTheFilters: false,
      },
      {
        name: 'lastAlertDateFrom',
        setState: setLastAlertDateFrom,
        inTheFilters: true,
        rules: ['Date'],
        options: getLastAlert(t),
        visible: hasInventoryRole(roles.INVENT_LAST_ALERT_DATE),
        category: 'date',
      },
      // {
      //   name: 'consumptionFrom',
      //   setState: setConsumptionFrom,
      //   inTheFilters: true,
      //   rules: ['RangeValues', 'MB'],
      //   range: {
      //     from: 'consumptionFrom',
      //     to: 'consumptionTo',
      //   },
      //   visible: hasInventoryRole(roles.INVENT_CONSUMPTION),
      //   category: 'range',
      // },
      // {
      //   name: 'consumptionTo',
      //   setState: setConsumptionTo,
      //   inTheFilters: false,
      // },
      {
        name: 'msisdn',
        setState: setMsisdn,
        inTheFilters: true,
        visible: hasInventoryRole(roles.INVENT_MSISDN),
        rules: ['Equals'],
        category: 'details',
      },

      {
        name: 'imsi',
        setState: setImsi,
        inTheFilters: true,
        visible: hasInventoryRole(roles.INVENT_IMSI),
        rules: ['Equals'],
        category: 'details',
      },
      {
        name: 'iccid',
        setState: setIccid,
        inTheFilters: true,
        visible: hasInventoryRole(roles.INVENT_ICCID),
        rules: ['Equals'],
        category: 'details',
      },
      {
        name: 'technology',
        setState: setTechnology,
        inTheFilters: true,
        visible: hasInventoryRole(roles.INVENT_TECHNOLOGY),
        options: technologyOptions,
        rules: ['Options'],
        category: 'details',
      },
      {
        name: 'apn',
        setState: setApn,
        inTheFilters: true,
        visible: hasInventoryRole(roles.INVENT_APN),
        rules: ['Options'],
        category: 'details',
      },

      {
        name: 'provisionedApn',
        setState: setProvisionedApn,
        inTheFilters: true,
        visible: hasInventoryRole(roles.INVENT_APN),
        rules: ['Options'],
        category: 'details',
      },
      {
        name: 'nickname',
        setState: setNickname,
        inTheFilters: true,
        visible: hasInventoryRole(roles.INVENT_NICKNAME),
        rules: ['Equals'],
        category: 'details',
      },
      // {
      //   name: 'customerDocumentNumber',
      //   setState: setCustomerDocumentNumber,
      //   inTheFilters: true,
      //   visible: hasInventoryRole(roles.INVENT_CUSTOMER_DOCUMENT_NUMBER),
      //   rules: ['Equals'],
      //   category: 'details',
      // },
      {
        name: 'imei',
        setState: setImei,
        inTheFilters: true,
        visible: hasInventoryRole(roles.INVENT_IMEI),
        rules: ['Equals'],
        category: 'details',
      },
      {
        name: 'imeiLocked',
        setState: setImeiLocked,
        inTheFilters: true,
        visible: hasInventoryRole(roles.INVENT_IMEI_LOCKED),
        rules: ['Options'],
        options: [
          {
            label: t('imei-lock.status.lock'),
            value: 'true',
          },
          {
            label: t('imei-lock.status.unlock'),
            value: 'false',
          },
        ],
        category: 'contract',
      },

      // {
      //   name: 'observation',
      //   setState: setObservation,
      //   inTheFilters: true,
      //   visible: hasInventoryRole(roles.INVENT_OBSERVATION),
      //   rules: ['Equals'],
      //   category: 'details',
      // },
      {
        name: 'status',
        setState: setStatus,
        inTheFilters: true,
        rules: ['Options'],
        options: getStatus(t).map(statusOption => ({
          label: statusOption.label,
          value: statusOption.value,
        })),
        visible: hasInventoryRole(roles.INVENT_STATUS),
        category: 'details',
      },

      // {
      //   name: 'city',
      //   setState: setCity,
      //   inTheFilters: true,
      //   visible: hasInventoryRole(roles.INVENT_CITY),
      //   rules: ['Equals'],
      //   category: 'location',
      // },
      // {
      //   name: 'state',
      //   setState: setState,
      //   inTheFilters: true,
      //   visible: hasInventoryRole(roles.INVENT_STATE),
      //   rules: ['Equals'],
      //   category: 'location',
      // },
      // {
      //   name: 'postalCode',
      //   setState: setPostalCode,
      //   inTheFilters: true,
      //   visible: hasInventoryRole(roles.INVENT_POSTAL_CODE),
      //   rules: ['Equals'],
      //   category: 'location',
      // },

      {
        name: 'consumptionPercentFrom',
        setState: setConsumptionPercentFrom,
        inTheFilters: true,
        rules: ['RangeValues', '%'],
        range: {
          from: 'consumptionPercentFrom',
          to: 'consumptionPercentTo',
        },
        visible: hasInventoryRole(roles.INVENT_CONSUMPTION),
        category: 'range',
      },
      {
        name: 'consumptionPercentTo',
        setState: setConsumptionPercentTo,
        inTheFilters: false,
      },
      {
        name: 'networkBlockStatus',
        setState: setNetworkBlockStatus,
        rules: ['Options'],
        inTheFilters: true,
        options: getStatusBlock(t),
        visible: hasInventoryRole(roles.INVENT_NETWORK_BLOCK_STATUS),
        category: 'contract',
      },
      // {
      //   name: 'hostname',
      //   setState: setHostname,
      //   inTheFilters: true,
      //   rules: ['Equals'],
      //   visible: hasInventoryRole(roles.INVENT_HOSTNAME),
      //   category: 'contract',
      // },
    ],
    [
      operatorsResponse,
      hasInventoryRole,
      t,
      operators,
      customersData,
      hasCustomersRoles,
      selectedStock,
      showContracted,
      isAllcom,
    ],
  );

  const { handleClearParams, handleSetSearchParams } = useSearchParams(
    paramsAttributes,
    setShowFilters,
  );

  useEffect(() => {
    if (isEndCustomer) {
      setSelectedStock('null');
      setShowContracted('true');
      handleClearParams(['showContracted']);
    }
  }, [customerType, isEndCustomer, handleClearParams]);

  const allParams = useMemo(
    () => ({
      online,
      customerId: customer,
      franchise,
      nickname,
      customerDocumentNumber,
      imei,
      imeiLocked,
      contractAssetNumber,
      connectedOperator,
      subOperator,
      observation,
      status,
      city,
      state,
      accountId,
      plan,
      postalCode,
      consumptionPercentFrom:
        consumptionPercentFrom && Number(consumptionPercentFrom),
      consumptionPercentTo:
        consumptionPercentTo && Number(consumptionPercentTo),
      networkBlockStatus,
      blockedByDefault,
      accountDesc,
      loyaltyType,
      loyaltyTime,
      cancellationFeeFrom: cancellationFeeFrom && Number(cancellationFeeFrom),
      cancellationFeeTo: cancellationFeeTo && Number(cancellationFeeTo),
      activationPriceFrom: activationPriceFrom && Number(activationPriceFrom),
      activationPriceTo: activationPriceTo && Number(activationPriceTo),
      priceFrom: priceFrom && Number(priceFrom),
      priceTo: priceTo && Number(priceTo),
      megabyteExcPriceFrom:
        megabyteExcPriceFrom && Number(megabyteExcPriceFrom),
      megabyteExcPriceTo: megabyteExcPriceTo && Number(megabyteExcPriceTo),
      simcardCreditFrom: simcardCreditFrom && Number(simcardCreditFrom),
      simcardCreditTo: simcardCreditTo && Number(simcardCreditTo),
      tradeInMonths,
      preActivationDays,
      suspensionEligibility,
      sharingType,
      brokerContractId,
      brokerContractDesc,
      sharedContractCreditFrom:
        sharedContractCreditFrom && Number(sharedContractCreditFrom),
      sharedContractCreditTo:
        sharedContractCreditTo && Number(sharedContractCreditTo),
      sharedContractFranchiseFrom:
        sharedContractFranchiseFrom && Number(sharedContractFranchiseFrom),
      sharedContractFranchiseTo:
        sharedContractFranchiseTo && Number(sharedContractFranchiseTo),
      hostname,
      consumptionFrom: consumptionFrom && Number(consumptionFrom) * 100000,
      consumptionTo: consumptionTo && Number(consumptionTo) * 100000,
      automaticBlocking,
      operator,
      apn,
      provisionedApn,
      technology: technology?.toUpperCase(),
      msisdn: msisdn ? msisdn.split(',') : undefined,
      imsi: imsi ? imsi.split(',') : undefined,
      iccid: iccid ? iccid.split(',') : undefined,
      planTypes: planTypes ? planTypes.split(',') : undefined,
      showContracted: isEndCustomer ? 'true' : showContracted ?? 'false', // true = what i pay ; false = what i charge;
      lastAlertDateFrom,
      preActivationEndDateFrom:
        preActivationEndDateFrom &&
        getDateWithISOFormat(preActivationEndDateFrom, 'start'),
      preActivationEndDateTo:
        preActivationEndDateTo &&
        getDateWithISOFormat(preActivationEndDateTo, 'end'),
      cancellationDateFrom:
        cancellationDateFrom &&
        getDateWithISOFormat(cancellationDateFrom, 'start'),
      cancellationDateTo:
        cancellationDateTo && getDateWithISOFormat(cancellationDateTo, 'end'),
      suspensionStartDateFrom:
        suspensionStartDateFrom &&
        getDateWithISOFormat(suspensionStartDateFrom, 'start'),
      suspensionStartDateTo:
        suspensionStartDateTo &&
        getDateWithISOFormat(suspensionStartDateTo, 'end'),
      suspensionEndDateFrom:
        suspensionEndDateFrom &&
        getDateWithISOFormat(suspensionEndDateFrom, 'start'),
      suspensionEndDateTo:
        suspensionEndDateTo && getDateWithISOFormat(suspensionEndDateTo, 'end'),
      stock:
        selectedStock === 'null' ? JSON.parse(selectedStock) : selectedStock,
      activationDateFrom:
        activationDateFrom && getDateWithISOFormat(activationDateFrom, 'start'),
      activationDateTo:
        activationDateTo && getDateWithISOFormat(activationDateTo, 'end'),
      lastConnectionFrom:
        lastConnectionFrom && getDateWithISOFormat(lastConnectionFrom, 'start'),
      lastConnectionTo:
        lastConnectionTo && getDateWithISOFormat(lastConnectionTo, 'end'),
      ...defaultFilters,
    }),
    [
      customerDocumentNumber,
      activationDateFrom,
      activationDateTo,
      apn,
      provisionedApn,
      automaticBlocking,
      consumptionFrom,
      consumptionTo,
      iccid,
      imsi,
      isEndCustomer,
      lastConnectionFrom,
      lastConnectionTo,
      msisdn,
      nickname,
      online,
      selectedStock,
      showContracted,
      technology,
      imei,
      subOperator,
      imeiLocked,
      defaultFilters,
      accountDesc,
      activationPriceFrom,
      activationPriceTo,
      blockedByDefault,
      brokerContractDesc,
      brokerContractId,
      operator,
      cancellationDateFrom,
      cancellationDateTo,
      cancellationFeeFrom,
      cancellationFeeTo,
      city,
      consumptionPercentFrom,
      consumptionPercentTo,
      contractAssetNumber,
      customer,
      franchise,
      accountId,
      plan,
      hostname,
      loyaltyTime,
      connectedOperator,
      loyaltyType,
      megabyteExcPriceFrom,
      megabyteExcPriceTo,
      networkBlockStatus,
      observation,
      postalCode,
      preActivationDays,
      preActivationEndDateFrom,
      preActivationEndDateTo,
      priceFrom,
      priceTo,
      sharedContractCreditFrom,
      sharedContractCreditTo,
      sharedContractFranchiseFrom,
      sharedContractFranchiseTo,
      sharingType,
      simcardCreditFrom,
      simcardCreditTo,
      state,
      status,
      suspensionEligibility,
      suspensionEndDateFrom,
      suspensionEndDateTo,
      suspensionStartDateFrom,
      suspensionStartDateTo,
      tradeInMonths,
      lastAlertDateFrom,
      planTypes,
    ],
  );

  const { data: inventoryData } = useSwr(
    '/service-proxy/inventory/MOT',
    {
      filters: {
        ...allParams,
      },
      pagination: {
        page: 0,
        linesPerPage: pagination.linesPerPage,
      },
    },
    {
      method: 'post',
      revalidateOnFocus: false,
      shouldRetryOnError: true,
    },
  );

  const { data: eventsAlertsData } = useSwr(
    lastAlertDateFrom ? `/service-proxy/alert-events/summary` : null,
    {
      dateFrom: new Date(lastAlertDateFrom),
    },
    {
      refreshInterval: 300000,
    },
  );

  useEffect(() => {
    if (inventoryData) {
      setPagination(oldPagination => ({
        ...oldPagination,
        total: inventoryData.totalElements,
      }));
    }
  }, [inventoryData]);

  const isIsim = useMemo(
    () => contracts?.length === 1 && contracts?.includes(ProductTypes.ISIM),
    [contracts],
  );

  const isMultiConnect = useMemo(
    () => contracts?.includes(ProductTypes.IOT_MULTI_CONECT),
    [contracts],
  );

  const isMoT = useMemo(
    () => contracts?.includes(ProductTypes.MOT_MANAGER_OF_THINGS_ENTERPRISE),
    [contracts],
  );

  const columnApn = useCallback(
    value => {
      return (
        value?.length > 0 && (
          <Tooltip
            title={
              <>
                <List
                  dataSource={value}
                  renderItem={item => (
                    <List.Item style={{ padding: '18px' }}>
                      <Typography.Text>{item}</Typography.Text>
                    </List.Item>
                  )}
                  style={{
                    maxHeight: '200px',
                    overflow: 'auto',
                    backgroundColor: 'white',
                  }}
                />
              </>
            }
            className="apn-tooltip"
          >
            <Button type="link">
              {`${value.length} ${t('attributes:apn')}`}
            </Button>
          </Tooltip>
        )
      );
    },
    [t],
  );

  const columnConsumption = useCallback(
    (value, record) => {
      const {
        consumptionTotal,
        consumptionCurrentDay,
        consumptionPercent,
        consumptionValidated,
        simcardCredit,
      } = record;

      const percent = consumptionPercent;

      const percentValidated =
        consumptionValidated && consumptionTotal
          ? (Number(consumptionValidated) * consumptionPercent) /
              consumptionTotal +
            (simcardCredit || 0)
          : 0;

      let strokeColorDark = '#27B502';
      let strokeColor = '#2FD105';
      if (percent >= 80 && percent <= 100) {
        strokeColorDark = '#EA9801';
        strokeColor = '#ffa500';
      } else if (percent > 100) {
        strokeColorDark = '#CD022A';
        strokeColor = '#EC0231';
      }

      const sizeConsumption = bytesFormat(value);
      const sizeConsumptionCurrentDay = bytesFormat(consumptionCurrentDay);
      const sizeConsumptionValidated = bytesFormat(consumptionValidated);
      const sizeFranchising = bytesFormat(
        (record.franchise + (simcardCredit || 0)) * 1024 * 1024,
      );
      const dataContentPopover = [
        {
          title: t('consumption.validated'),
          value: sizeConsumptionValidated,
          color: strokeColor,
          show: consumptionValidated,
        },
        {
          title: t('consumption.daily'),
          value: sizeConsumptionCurrentDay,
          color: strokeColorDark,
          show: consumptionCurrentDay,
        },
      ];

      return (
        <div className="col-line-consumption">
          <p style={{ margin: 0, fontSize: '0.86rem' }}>
            {percent && percent !== 0
              ? `${sizeConsumption} / ${sizeFranchising}`
              : sizeConsumption}
          </p>
          {percent !== 0 && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Tooltip
                title={`${
                  percent && percent >= 1
                    ? Math.trunc(percent)
                    : percent?.toFixed(2)
                }%`}
              >
                <Progress
                  percent={percent}
                  trailColor="#fdfdfd"
                  showInfo={false}
                  strokeColor={strokeColorDark}
                  success={{
                    percent: percentValidated,
                    strokeColor,
                  }}
                  className="progress-bar-consumption"
                />
              </Tooltip>

              <CustomPopover
                title={t('table.consumption')}
                titleValue={sizeConsumption}
                titleContent=""
                dataContent={dataContentPopover.filter(
                  item => item.show !== 0 && item.show,
                )}
              >
                <Button
                  type="link"
                  style={{ padding: 0, margin: 0, marginLeft: 6 }}
                >
                  <PlusCircleOutlined />
                </Button>
              </CustomPopover>
            </div>
          )}
        </div>
      );
    },
    [t],
  );
  const columnFranchise = useCallback(
    (value, record) => {
      const { simcardCredit, sharedCredit } = record;

      const dataContentPopover = [
        {
          title: t('franchise.sim-card'),
          value: `+ ${simcardCredit} MB`,
          color: 'green',
          show: simcardCredit,
        },
        {
          title: t('franchise.contract'),
          value: `+ ${sharedCredit} MB`,
          color: 'blue',
          show: sharedCredit,
        },
      ];

      const sizeFranchising = bytesFormat(value * 1024 * 1024);

      return (
        <div>
          {value && value !== 0 && (
            <>
              <span style={{ margin: 0, fontSize: '0.86rem' }}>
                {sizeFranchising}
              </span>
              {(sharedCredit && sharedCredit !== 0) ||
              (simcardCredit && simcardCredit !== 0) ? (
                <CustomPopover
                  title={t('table.franchising')}
                  titleValue={!value ? '' : `${value} MB`}
                  titleContent={t('franchise.additional')}
                  dataContent={dataContentPopover.filter(
                    item => item.show !== 0 && item.show,
                  )}
                >
                  <Button type="link" style={{ padding: 0, marginLeft: 6 }}>
                    <PlusCircleOutlined />
                  </Button>
                </CustomPopover>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      );
    },
    [t],
  );

  const handleSuspensionDate = useCallback((record, text) => {
    const initialDate = getDateFromPartiallyInIsoFormat(
      record.suspensionInitialDate,
    );
    const finalDate = getDateFromPartiallyInIsoFormat(
      record.suspensionFinalDate,
    );
    if (initialDate || finalDate) {
      const date = `${text ? `${text}: ` : ''}${initialDate ?? ''}${
        finalDate ? ` - ${finalDate}` : ''
      }`;
      return (
        <Tooltip title={date}>
          <RiCalendarTodoFill
            style={{
              margin: 2,
              opacity: 0.4,
              color: 'green',
              cursor: 'pointer',
              fontSize: 18,
              marginBottom: '-4px',
            }}
          />
        </Tooltip>
      );
    }
  }, []);

  const handleCancellationDate = useCallback((record, text) => {
    const cancellationDate = extractDateInISOFormat(record.cancellationDate);

    if (cancellationDate) {
      const date = `${text ? `${text}: ` : ''}${cancellationDate ?? ''}`;
      return (
        <Tooltip title={date}>
          <RiCalendarTodoFill
            style={{
              margin: 2,
              opacity: 0.4,
              color: 'green',
              cursor: 'pointer',
              fontSize: 18,
              marginBottom: '-4px',
            }}
          />
        </Tooltip>
      );
    }
  }, []);

  const allColumns = useMemo(() => {
    return [
      {
        title: '',
        dataIndex: 'actions',
        visible: true,
        render: (text, record) => {
          const haveLastAlert =
            Boolean(record.lastAlertDate) &&
            moment(record.lastAlertDate).isAfter(moment().subtract(7, 'days'));

          return (
            <div>
              {haveLastAlert && (
                <Tooltip title={t('attributes:newAlert')}>
                  <Button
                    danger
                    type="link"
                    size={'large'}
                    icon={<WarningFilled />}
                  />
                </Tooltip>
              )}
              {userRoles?.inventory && (
                <Tooltip title={t('details')}>
                  <Button
                    type="link"
                    icon={<FileSearchOutlined />}
                    onClick={() => handleShowDrawer(record)}
                  />
                </Tooltip>
              )}
              {!isCustomerFinanciallyBlocked && (
                <TableMenu
                  sim={record}
                  isStock={selectedStock === 'true'}
                  showContracted={showContracted}
                />
              )}
            </div>
          );
        },
      },

      {
        title: t('table.consumption'),
        dataIndex: 'consumptionTotal',
        align: 'center',
        render: (value, row) =>
          value ? columnConsumption(value, row) : undefined,
      },
      ...(isAllcom && showContracted === 'true'
        ? [
            {
              title: t('table.contractAssetNumber'),
              dataIndex: 'contractAssetNumber',
            },
            {
              title: t('table.plan'),
              dataIndex: 'plan',
            },
          ]
        : []),
      ...(showContracted !== 'true' || !isAllcom
        ? [
            {
              title: t('table.blockedByDefault'),
              dataIndex: 'blockedByDefault',
              render: value => {
                const [color, text] = value
                  ? ['green', 'activated']
                  : ['red', 'disabled'];

                return (
                  <Tag color={color}>
                    {t(`attributes:automaticBlocking.${text}`).toUpperCase()}
                  </Tag>
                );
              },
              align: 'center',
            },
            {
              title: t('table.brokerContractDesc'),
              dataIndex: 'brokerContractDesc',
              render: (value, record) =>
                record.brokerContractId ? (
                  <Tooltip title={t('view-request')}>
                    <Button
                      type="link"
                      onClick={() =>
                        navigate(
                          `/financial/contracts/${record.brokerContractId}?showContracted=${showContracted}`,
                        )
                      }
                    >
                      {value}
                    </Button>
                  </Tooltip>
                ) : (
                  value
                ),
            },
            {
              title: t('table.preActivationDays'),
              dataIndex: 'preActivationDays',
              align: 'center',
            },
            {
              title: t('table.loyaltyType'),
              dataIndex: 'loyaltyType',
              render: value => t(`typesOfPenalty.${value || 0}`),
            },
            {
              title: t('table.tradeInMonths'),
              dataIndex: 'tradeInMonths',
            },
            {
              title: t('table.request'),
              dataIndex: 'accountDesc',
              render: (value, record) =>
                record.accountId ? (
                  <Tooltip title={t('view-request')}>
                    <Button
                      type="link"
                      onClick={() =>
                        navigate(`/financial/requests/${record.accountId}`)
                      }
                    >
                      {value}
                    </Button>
                  </Tooltip>
                ) : (
                  value
                ),
            },
            {
              title: t('attributes:automaticBlocking.title'),
              dataIndex: 'automaticBlocking',
              align: 'center',
              render: value => {
                const [color, text] = value
                  ? ['green', 'activated']
                  : ['red', 'disabled'];

                return (
                  <Tag color={color}>
                    {t(`attributes:automaticBlocking.${text}`).toUpperCase()}
                  </Tag>
                );
              },
            },
            {
              title: t('table.roaming'),
              dataIndex: 'roaming',
              align: 'center',
              render: value => {
                const [color, text] = value
                  ? ['green', 'activated']
                  : ['red', 'disabled'];

                if (value || value === false) {
                  return (
                    <Tag color={color}>
                      {t(`attributes:automaticBlocking.${text}`).toUpperCase()}
                    </Tag>
                  );
                }
              },
            },
            {
              title: t('table.sharingType'),
              dataIndex: 'sharingType',
              render: value => t(`sharingType.${value || 'NONE'}`),
            },
            {
              title: t('table.preActivationEnd'),
              dataIndex: 'preActivationEnd',
              render: extractDateInISOFormat,
            },
            {
              title: t('table.mbExcPrice'),
              dataIndex: 'mbExcPrice',
              render: value => formatCurrency(Number(value || 0)),
            },
            {
              title: t('table.penalty'),
              dataIndex: 'penalty',
              render: value => formatCurrency(Number(value || 0)),
            },
            {
              title: t('table.is-suspendable'),
              dataIndex: 'eligibleToSuspension',
              align: 'center',
              render: (value, record) => (
                <Badge
                  className="badge-status-table-cell"
                  status={t(value ? `status:badgeStatus.ONLINE` : 'error')}
                  text={
                    record?.status &&
                    handleSuspensionDate(record, t('last-suspension'))
                  }
                />
              ),
            },
          ]
        : []),

      ...(showContracted === 'true'
        ? [
            {
              title: t('table.parentName'),
              dataIndex: 'parentName',
              align: 'center',
            },
            {
              title: t('table.parentDocumentNumber'),
              dataIndex: 'parentDocumentNumber',
              align: 'center',
            },
          ]
        : [
            {
              title: t('table.owner'),
              dataIndex: 'customerName',
            },
            {
              title: t('table.customerDocumentNumber'),
              dataIndex: 'customerDocumentNumber',
            },
            {
              title: t('table.franchising'),
              dataIndex: 'franchise',
              align: 'center',
              render: (value, row) =>
                value ? columnFranchise(value, row) : undefined,
            },
          ]),
      ...(showContracted === 'true' && isAllcom
        ? []
        : [
            {
              title: t('table.planTypes'),
              dataIndex: 'planType',
              align: 'center',
              render: value => value && t(`planTypes.${value}`),
            },
          ]),
      {
        title: t('table.apn'),
        dataIndex: 'apn',
      },

      {
        title: t('table.provisionedApn'),
        dataIndex: 'apns',
        align: 'center',
        render: value => (value ? columnApn(value) : undefined),
      },
      ...(isMultiConnect
        ? []
        : [
            {
              title: t('table.operator'),
              dataIndex: 'operator',
            },
          ]),
      {
        title: t('table.connected-operator'),
        dataIndex: 'connectedOperator',
        render: value => (value === 'ALGAR' ? 'ALGAR TELECOM' : value),
      },
      {
        title: t('table.lastAlertDate'),
        dataIndex: 'lastAlertDate',
        render: value => value && dateFormat(value),
      },
      {
        title: t('table.subOperator'),
        dataIndex: 'subOperator',
        render: value => (value === 'ALGAR' ? 'ALGAR TELECOM' : value),
      },
      {
        title: t('table.technology'),
        dataIndex: 'technology',
        align: 'center',
      },

      {
        title: t('table.network-status'),
        dataIndex: 'online',
        align: 'center',
        render: value => (
          <Badge
            className="badge-status-table-cell"
            status={t(value ? `status:badgeStatus.ONLINE` : 'error')}
          />
        ),
      },
      {
        title: t('table.nickname'),
        dataIndex: 'nickname',
      },
      {
        title: t('table.lock-status'),
        dataIndex: 'networkBlockStatus',
        render: (value, record) => (
          <BlockStatusTag
            blockType={value}
            blockingInitialDate={record.networkBlockStartDate}
            blockingFinalDate={record.networkBlockEndDate}
          />
        ),
      },
      {
        title: t('table.caller-id'),
        dataIndex: 'msisdn',
      },
      ...(isIsim
        ? []
        : [
            {
              title: t('table.iccid'),
              dataIndex: 'iccid',
            },
          ]),
      ...(isIsim
        ? []
        : [
            {
              title: t('table.imsi'),
              dataIndex: 'imsi',
            },
          ]),
      {
        title: t('table.cancellationDate'),
        dataIndex: 'cancellationDate',
        render: extractDateInISOFormat,
      },
      {
        title: t('table.last-connection'),
        dataIndex: 'lastConnection',
        render: dateFormat,
      },
      {
        title: t('table.lastStatusUpdate'),
        dataIndex: 'lastStatusUpdate',
        render: dateFormat,
      },
      {
        title: t('table.lastUpdate'),
        dataIndex: 'lastUpdate',
        render: dateFormat,
      },
      {
        title: t('table.suspensionStartDate'),
        dataIndex: 'suspensionStartDate',
        render: extractDateInISOFormat,
      },
      {
        title: t('table.suspensionEndDate'),
        dataIndex: 'suspensionEndDate',
        render: extractDateInISOFormat,
      },
      {
        title: t('table.observation'),
        dataIndex: 'observation',
        align: 'center',
      },

      {
        title: t('table.postalCode'),
        dataIndex: 'postalCode',
      },
      {
        title: t('table.city'),
        dataIndex: 'city',
      },
      { title: t('table.latitude'), dataIndex: 'latitude' },
      { title: t('table.longitude'), dataIndex: 'longitude' },
      {
        title: t('table.state'),
        dataIndex: 'state',
      },

      {
        title: t('table.status'),
        dataIndex: 'status',
        render: (status, record) => (
          <div
            style={{
              display: 'inline-flex',
              alignItems: 'center',
            }}
          >
            <Tag color={status ? t(`status:colors.${status}`) : 'red'}>
              {t(`status:${status}`).toUpperCase()}
            </Tag>
            {status === 'RENOVACAO_EM_ANALISE' ||
              (status === 'RENOVACAO_AGENDADA' &&
                handleCancellationDate(
                  record,
                  t(`status:${status}`).toUpperCase(),
                ))}
            {!status && handleSuspensionDate(record)}
          </div>
        ),
      },
      {
        title: t('table.activation-date'),
        dataIndex: 'activationDate',
        align: 'center',
        render: extractDateInISOFormat,
      },
      {
        title: t('table.imei'),
        dataIndex: 'imei',
      },
      {
        title: t('table.hostname'),
        dataIndex: 'hostname',
      },
      {
        title: t('table.imei-lock'),
        dataIndex: 'imeiLocked',
        align: 'center',
        render: value => {
          const [statusColor, statusType] = value
            ? ['red', 'lock']
            : ['green', 'unlock'];

          return (
            <Tag color={statusColor}>
              {t(`imei-lock.status.${statusType}`).toUpperCase()}
            </Tag>
          );
        },
      },
    ];
  }, [
    showContracted,
    t,
    columnConsumption,
    isMultiConnect,
    columnFranchise,
    isIsim,
    isAllcom,
    userRoles?.inventory,
    isCustomerFinanciallyBlocked,
    selectedStock,
    navigate,
    handleSuspensionDate,
    columnApn,
    handleCancellationDate,
  ]);

  const columnsFilter = useMemo(() => {
    if (customerLogged?.parentId || showContracted !== 'true') {
      allColumns.push(
        {
          title: t('table.price'),
          dataIndex: 'price',
          render: value => formatCurrency(Number(value || 0)),
        },
        {
          title: t('table.activation-price'),
          dataIndex: 'activationPrice',
          align: 'center',
          render: value => formatCurrency(Number(value || 0)),
        },
      );
    }

    const filteredColumns = allColumns.filter(
      column => column.visible || hasRole(column.dataIndex, 'inventory'),
    );

    if (defaultColumns) {
      return filteredColumns.filter(column =>
        defaultColumns.includes(column.dataIndex),
      );
    }
    return filteredColumns;
  }, [
    allColumns,
    customerLogged?.parentId,
    defaultColumns,
    hasRole,
    showContracted,
    t,
  ]);

  const handleSetSelectedRows = (keys, rows) => {
    setSelectedRows(rows);
  };

  const handleActionClick = useCallback(key => {
    setActionToExecute(Number(key));
    setModalDeviceActionsVisible(true);
  }, []);

  const devicesWithLockUnlock = useMemo(
    () => ({
      type: 'both',
      devices: selectedRows,
    }),
    [selectedRows],
  );

  const doubleActions = useMemo(() => {
    const defaultActions = [
      {
        actionRole: 'NETWORK_BLOCK_UNLOCK',
        actionBlock: 'NETWORK_BLOCK',
        actionUnlock: 'NETWORK_UNLOCK',
        selectedDevices: devicesWithLockUnlock,
      },
      {
        actionRole: 'IMEI_LOCK_UNLOCK',
        actionBlock: 'IMEI_LOCK',
        actionUnlock: 'IMEI_UNLOCK',
        selectedDevices: devicesWithLockUnlock,
      },
    ];
    if (canSeeSuspensionAction) {
      defaultActions.push(
        {
          actionRole: 'SUSPENSION_WITHDRAWAL',
          actionBlock: 'SUSPENSION',
          actionUnlock: 'WITHDRAWAL',
          selectedDevices: devicesWithLockUnlock,
        },
        {
          actionRole: 'BROKER_CANCELLATION',
          actionBlock: 'BROKER_CANCELLATION',
          actionUnlock: 'BROKER_CANCELLATION_WAIVER',
          selectedDevices: devicesWithLockUnlock,
        },
      );
    }
    return defaultActions;
  }, [canSeeSuspensionAction, devicesWithLockUnlock]);

  const menu = useMemo(() => {
    const isMenuDisabled =
      !hasRole(roles.BATCH_ACTIONS, 'portal') && selectedRows?.length !== 1;

    return (
      <Space>
        <RequestReport
          items={[
            {
              role: roles.REQUEST_REPORT,
              filters: allParams,
              path: '/service-proxy/inventory/MOT/report',
              type: 'MOT_INVENTORY',
              method: 'post',
            },
          ]}
        />
        {!isCustomerFinanciallyBlocked && (
          <>
            {!isInTheRequestDetails && (
              <RoleChecker role={roles.SIMCARD_IMPORT}>
                {isMoT && (
                  <Tooltip
                    title={
                      selectedRows?.length > 0
                        ? t('rowsSelectedImport')
                        : t('import')
                    }
                  >
                    <Button
                      icon={<PlusCircleOutlined />}
                      color="red"
                      type={'link'}
                      disabled={selectedRows?.length > 0}
                      onClick={() => handleActionClick(ActionTypes.ACTIVATION)}
                    />
                  </Tooltip>
                )}
              </RoleChecker>
            )}

            <DeviceActionsMenu
              button={
                <Tooltip
                  title={t(
                    `table.${isMenuDisabled ? 'batch-not-allowed' : 'actions'}`,
                  )}
                >
                  <Button
                    icon={<ControlOutlined />}
                    size={'large'}
                    type={'link'}
                    disabled={isMenuDisabled}
                  />
                </Tooltip>
              }
              onActionClick={({ key }) => handleActionClick(key)}
              doubleActions={doubleActions}
              stock={selectedStock === 'true'}
              showContracted={showContracted}
              disabled={isMenuDisabled}
              isMoT={isMoT}
            />
          </>
        )}
      </Space>
    );
  }, [
    hasRole,
    selectedRows?.length,
    allParams,
    isCustomerFinanciallyBlocked,
    isInTheRequestDetails,
    isMoT,
    t,
    doubleActions,
    selectedStock,
    showContracted,
    handleActionClick,
  ]);

  const handleTabFilterChange = useCallback(
    value => {
      switch (value) {
        case 'null':
          setSelectedStock('null');
          handleClearParams(['stock']);
          break;
        case 'true':
          handleSetSearchParams({ stock: value }, ['customer']);
          break;
        case 'false':
          handleSetSearchParams({ stock: value });
          break;
        default:
          break;
      }
    },
    [handleSetSearchParams, handleClearParams],
  );

  const handleShowFilters = useCallback(
    value => {
      setShowFilters(value);
      // As the 'showFilters' has not changed yet, within this function,
      // its logic is the reverse to clear all filters (false = true)
      if (showFilters) {
        if (!isEndCustomer) {
          handleClearParams(['showContracted'], 'except');
        } else {
          handleClearParams();
        }
      }
    },
    [showFilters, handleClearParams, isEndCustomer],
  );

  const handleTabsChange = useCallback(
    key => {
      if (!isEndCustomer) {
        if (key === 'true') {
          handleSetSearchParams({ showContracted: key });
        } else {
          setShowContracted('false');
          handleClearParams(['stock', 'showContracted']);
        }
      }
    },
    [handleSetSearchParams, handleClearParams, isEndCustomer],
  );

  const removeItemsWithouthPermission = useMemo(
    () => paramsAttributes.filter(item => item.visible && item.inTheFilters),
    [paramsAttributes],
  );

  const search = useLocation().search;

  const allDefaultParams = useMemo(() => {
    const params = [];
    paramsAttributes?.forEach(attr => {
      params.push(attr.name);
    });
    return params;
  }, [paramsAttributes]);

  const params = useMemo(
    () => getSearchParam(search, allDefaultParams) ?? {},
    [search, allDefaultParams],
  );
  const tableKey = useMemo(() => {
    return isInTheRequestDetails
      ? 'requestDetails'
      : `inventory-${showContracted === 'true' && showContracted}`;
  }, [showContracted, isInTheRequestDetails]);

  const defaultValues = useMemo(() => {
    const tablePreferences = getTablePreferences(tableKey);

    const defaultValuesString = tablePreferences?.filterSelected || [
      'msisdn',
      'iccid',
      'imsi',
      'networkBlockStatus',
      'online',
      'status',
      'operator',
      'connectedOperator',
      'lastConnectionFrom',
      'customerName',
      'automaticBlocking',
      'technology',
      'apn',
      'provisionedApn',
      'activationDateFrom',
      'franchise',
      'nickname',
      'imei',
      'imeiLocked',
      'price',
      'consumptionPercentFrom',
      'consumptionPercentTo',
      'subOperator',
      'priceFrom',
      'priceTo',
      'lastAlertDateFrom',
    ];

    const defaultValuesOnParms = removeItemsWithouthPermission
      .map(itemOnFilter => {
        let result;
        if (itemOnFilter.date) {
          const startValue = params[itemOnFilter.date.start];
          const endValue = params[itemOnFilter.date.end];
          result =
            (startValue || endValue) && `${startValue || ''} ${endValue || ''}`;
        } else if (itemOnFilter.range) {
          const fromValue = params[itemOnFilter.range.from];
          const toValue = params[itemOnFilter.range.to];
          result =
            (fromValue || toValue) && `${fromValue || ''} ${toValue || ''}`;
        } else {
          result = params[itemOnFilter.name];
        }

        return {
          defaultValue: result,
          ...itemOnFilter,
        };
      })
      .filter(item => item.defaultValue !== undefined);

    const findDeafultFilters = removeItemsWithouthPermission?.filter(
      itemOnFilter =>
        defaultValuesString?.includes(itemOnFilter.name) &&
        itemOnFilter.name &&
        !defaultValuesOnParms?.some(item => item.name === itemOnFilter.name),
    );
    const selectedFilters =
      defaultValuesOnParms?.length > 0
        ? [...findDeafultFilters, ...defaultValuesOnParms]
        : findDeafultFilters;

    const orderMap = new Map();
    defaultValuesString.forEach((item, index) => {
      orderMap.set(item, index);
    });

    const sortedArray = [...selectedFilters].sort((a, b) => {
      const indexA = orderMap.get(a.name);
      const indexB = orderMap.get(b.name);
      if (indexA !== undefined && indexB !== undefined) {
        return indexA - indexB;
      }
      if (indexA !== undefined) {
        return -1;
      }
      if (indexB !== undefined) {
        return 1;
      }
      return 0;
    });

    return sortedArray;
  }, [params, removeItemsWithouthPermission, getTablePreferences, tableKey]);

  const [itemsToMap, setitemsToMap] = useState([...defaultValues]);

  const handleChange = (value, add, defaultValue) => {
    const findItem = paramsAttributes.find(
      filterItem => filterItem.name === value,
    );
    const index = itemsToMap?.findIndex(
      filterItem => filterItem.name === value,
    );

    if (add && findItem) {
      setitemsToMap(previous =>
        previous
          ? previous.map((item, i) =>
              i === index ? { ...findItem, defaultValue } : item,
            )
          : [{ ...findItem, defaultValue }],
      );
    } else {
      const itemWithtouDefaultValues = itemsToMap?.map(item =>
        item.name === value ? { ...item, defaultValue: undefined } : item,
      );
      handleClearParams([value]);
      setitemsToMap(itemWithtouDefaultValues);
    }
  };

  const handleRemoveDefaultValues = () =>
    setitemsToMap(
      itemsToMap?.map(item => {
        return {
          ...item,
          defaultValue: undefined,
        };
      }),
    );

  return (
    <>
      {!isInTheRequestDetails && (
        <>
          <RoleChecker role={roles.LINES_INFO}>
            <Widgets
              carrier={customer}
              client={customer || customerId}
              style={{ marginBottom: 16 }}
              handleSetSearchParams={handleSetSearchParams}
              filters={{
                showContracted: isEndCustomer
                  ? 'true'
                  : showContracted ?? 'false',
              }}
              handleChange={handleChange}
            />
          </RoleChecker>
          {!isEndCustomer && (
            <Tabs
              type="card"
              defaultActiveKey="receive"
              activeKey={showContracted}
              onChange={handleTabsChange}
              style={{ background: '#ffffff' }}
            >
              <TabPane
                key="false"
                tab={
                  <>
                    {t('visions-steps.receive')}
                    {eventsAlertsData?.items?.find(
                      events => events?.eventType === 'CONSUMPTION',
                    )?.hasAlertsToReceive &&
                      lastAlertDateFrom &&
                      showContracted === 'true' && (
                        <Tooltip title={t('has-alerts')}>
                          <ExclamationCircleOutlined
                            style={{
                              color: 'var(--primary-color)',
                              marginLeft: 8,
                            }}
                          />
                        </Tooltip>
                      )}
                  </>
                }
              />
              <TabPane
                key="true"
                tab={
                  <>
                    {t('visions-steps.pay')}
                    {eventsAlertsData?.items?.find(
                      events => events?.eventType === 'CONSUMPTION',
                    )?.hasAlertsToPay &&
                      lastAlertDateFrom &&
                      showContracted === 'false' && (
                        <Tooltip title={t('has-alerts')}>
                          <ExclamationCircleOutlined
                            style={{
                              color: 'var(--primary-color)',
                              marginLeft: 8,
                            }}
                          />
                        </Tooltip>
                      )}
                  </>
                }
              />
            </Tabs>
          )}
        </>
      )}
      <div style={{ background: '#ffffff' }}>
        <Table
          columns={columnsFilter}
          allColumns={allColumns}
          data={inventoryData?.content || []}
          loading={!inventoryData?.content}
          extraActions={menu}
          footer={() => (
            <>
              <Row justify="center">
                {inventoryData && (
                  <Text type="secondary" style={{ marginBottom: 5 }}>
                    {`${t('showing', {
                      count: inventoryData?.content?.length,
                    })} ${
                      inventoryData?.content
                        ? t('of', {
                            total: pagination.total,
                          })
                        : ''
                    } ${t('results')}`}
                  </Text>
                )}
              </Row>
              {inventoryData?.content?.length < pagination?.total && (
                <Row justify="center">
                  <Button
                    loading={!inventoryData}
                    type="primary"
                    onClick={() =>
                      setPagination(oldPagination => ({
                        ...oldPagination,
                        linesPerPage:
                          oldPagination.linesPerPage + oldPagination.pageSize,
                      }))
                    }
                  >
                    {t('load-more')}
                  </Button>
                  <Select
                    style={{ marginLeft: 4 }}
                    value={pagination?.pageSize}
                    onChange={value =>
                      setPagination(oldPagination => ({
                        ...oldPagination,
                        pageSize: value,
                        linesPerPage: value,
                      }))
                    }
                    options={pagination?.pageSizeOptions}
                  />
                </Row>
              )}
            </>
          )}
          pagination={false}
          setSelectedRowKeys={handleSetSelectedRows}
          tableKey={tableKey}
          tabsFilters={
            !isEndCustomer &&
            showContracted === 'true' && (
              <Tabs
                tabBarStyle={{
                  margin: 0,
                  padding: '0 20px',
                  background: showFilters ? '#ffffff' : '#f9f9f9',
                  borderBottom: '2px solid #808fe671',
                  active: 'red',
                }}
                activeKey={selectedStock}
                onChange={handleTabFilterChange}
              >
                {stock?.map(step => (
                  <TabPane key={step.value} tab={step.label} />
                ))}
              </Tabs>
            )
          }
          extraFilters={
            showFilters && (
              <QueryBuilder
                clearSearchParams={handleClearParams}
                itemsToMap={itemsToMap}
                setSearchParams={handleSetSearchParams}
                defaultValues={defaultValues}
                handleChange={handleChange}
                operatorsData={operatorsData}
              />
            )
          }
          queryFilters={{
            filters: paramsAttributes,
            clearSearchParams: () => {
              handleRemoveDefaultValues();
            },
            itemsToMap,
            setitemsToMap,
            showFilters,
          }}
          title={
            <>
              {t('title')}
              {inventoryData && (
                <span
                  style={{ fontSize: '0.9rem', opacity: 0.6, marginRight: 4 }}
                >
                  {` (${inventoryData?.content?.length} ${t('of', {
                    total: pagination.total,
                  })})`}
                </span>
              )}
              <Filters
                showFilters={showFilters}
                setShowFilters={handleShowFilters}
              />
            </>
          }
          selectable
          mainExtraAction={
            isInTheRequestDetails && (
              <>
                <Divider type="vertical" />
                <Tooltip title={t('requests:viewOnInventoryPage')}>
                  <Button
                    icon={
                      <FiExternalLink
                        style={{
                          fontSize: '1.4rem',
                          marginTop: 4,
                        }}
                      />
                    }
                    size="large"
                    type="link"
                    onClick={() => {
                      navigate(
                        `/management/inventory?showContracted=${defaultFilters?.showContracted}&accountId=${defaultFilters?.accountId}`,
                      );
                    }}
                  />
                </Tooltip>
              </>
            )
          }
        />
      </div>
      {userRoles?.inventory && previewDevice && (
        <InventoryPreview
          showContracted={showContracted === 'true'}
          deviceProps={previewDevice}
          drawerVisible={drawerVisible}
          setDrawerVisible={() => {
            setDrawerVisible(false);
            setPreviewDevice(undefined);
          }}
          doubleActions={doubleActions}
          stock={selectedStock === 'true'}
          availableOperators={operatorsData}
          dataContracts={contractsData}
        />
      )}
      <DeviceActionModal
        actionKey={actionToExecute}
        devices={selectedRows}
        visible={modalDeviceActionsVisible}
        onClose={() => {
          setModalDeviceActionsVisible(false);
          setActionToExecute(-1);
        }}
        showContracted={showContracted}
        doubleActions={doubleActions}
        availableOperators={operatorsData}
        dataContracts={contractsData}
      />
    </>
  );
};

export default Inventory;
