import { Card } from '@components/ui';
import KeycloakContext from '@store/KeycloakContext/KeycloakContext';
import { Col, Row, Space, Radio } from 'antd';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Usage from './Usage/Usage';
import roles from '@utils/rolesConstants';

import Actives from './Actives/Actives';
import OnlineOrOffline from './OnlineOrOffline/OnlineOrOffline';
import StatusLines from './StatusLines/StatusLines';
import TotalLines from './TotalLines/TotalLines';
import LinesCloseToExpire from './LinesCloseToExpire/LinesCloseToExpire';
import TradeIn from './TradeIn/TradeIn';
import './styles.less';
import SharedContracts from './SharedContracts/SharedContracts';
import SessionContext from '@src/store/SessionContext/SessionContext';

const Dashboard = () => {
  const { t } = useTranslation(['inventory', 'dashboard']);
  const { search } = useLocation();

  const query = new URLSearchParams(search);

  const clientId = query.get('clientId') ? query.get('clientId') : null;
  const name = query.get('clientName')
    ? query.get('clientName').split('"').join('')
    : null;

  const { keycloak } = useContext(KeycloakContext);
  const { userRoles } = useContext(SessionContext);
  const customerType = keycloak?.idTokenParsed?.customerType;

  const [showContracted, setShowContracted] = useState(
    customerType === 'CUSTOMER' ? true : false,
  );
  const hasRole = useCallback(
    (roleToCheck, client) => userRoles?.[client]?.includes(roleToCheck),
    [userRoles],
  );
  const customerId = clientId || keycloak?.idTokenParsed?.customerId;
  const isAllcom = customerId === 10000;
  const isNotSuperBroker = customerType !== 'SUPERBROKER';
  const showIscaData =
    (hasRole(roles.INVENT_ISCA, 'inventory') &&
      !isNotSuperBroker &&
      !showContracted) ||
    isNotSuperBroker;
  const showContractDash = (!showContracted && isAllcom) || isNotSuperBroker;
  return (
    <>
      <Row
        gutter={[16, 16]}
        style={{ width: '100%', marginLeft: '0px', marginRight: '0px' }}
      >
        {clientId ? (
          <Col xs={24}>
            <Card title={t('client')}>
              <div>
                <span>{t('client-id')}:</span> {customerId}
              </div>
              <div>
                <span>{t('client-name')}:</span> {name}
              </div>
            </Card>
          </Col>
        ) : null}

        {customerType !== 'CUSTOMER' && (
          <Col md={24} xs={24}>
            <Space direction="vertical" style={{ marginLeft: 16 }}>
              <Radio.Group
                onChange={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowContracted(e.target.value);
                }}
                value={showContracted}
              >
                <Radio value={false}>
                  {t('inventory:visions-steps.receive')}
                </Radio>
                <Radio value={true}>{t('inventory:visions-steps.pay')}</Radio>
              </Radio.Group>
            </Space>
          </Col>
        )}

        <Col lg={12} md={12} xl={6} xs={24}>
          <Actives customerId={customerId} filters={{ showContracted }} />
        </Col>

        {isAllcom && (
          <Col lg={12} md={12} xl={6} xs={24}>
            <TradeIn customerId={customerId} filters={{ showContracted }} />
          </Col>
        )}
        <Col lg={12} md={12} xl={6} xs={24}>
          <OnlineOrOffline
            customerId={customerId}
            filters={{ showContracted }}
            isOnline
          />
        </Col>
        <Col lg={12} md={12} xl={6} xs={24}>
          <OnlineOrOffline
            customerId={customerId}
            isOnline={false}
            filters={{ showContracted }}
          />
        </Col>
        <Col lg={12} md={12} xl={6} xs={24}>
          <StatusLines
            customerId={customerId}
            days={15}
            filters={{ showContracted }}
          />
        </Col>
        <Col lg={12} md={12} xl={6} xs={24}>
          <StatusLines
            customerId={customerId}
            days={30}
            filters={{ showContracted }}
          />
        </Col>
        <Col lg={12} md={12} xl={6} xs={24}>
          <StatusLines
            customerId={customerId}
            days={60}
            filters={{ showContracted }}
          />
        </Col>
        {showIscaData && (
          <Col lg={12} md={12} xl={6} xs={24}>
            <LinesCloseToExpire filters={{ showContracted }} />
          </Col>
        )}

        {isAllcom && (
          <Col lg={12} md={12} xl={6} xs={24}>
            <StatusLines
              customerId={customerId}
              days={90}
              filters={{ showContracted }}
            />
          </Col>
        )}
      </Row>
      <Row
        gutter={[16, 16]}
        style={{ marginTop: '16px', marginLeft: '0px', marginRight: '0px' }}
      >
        <Col lg={24} md={24} xl={12} xs={24}>
          <TotalLines isAllcom={isAllcom} filters={{ showContracted }} />
        </Col>

        {showContractDash && (
          <Col lg={24} md={24} xl={12} xs={24}>
            <SharedContracts isAllcom={isAllcom} filters={{ showContracted }} />
          </Col>
        )}

        <Col lg={24} md={24} xl={24} xs={24}>
          <Usage customerId={customerId} />
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
