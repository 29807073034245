import React, { useEffect, useContext, useMemo, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card } from '@components/ui';
import {
  Col,
  Row,
  Descriptions,
  Skeleton,
  Divider,
  Tag,
  Tooltip,
  Button,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import DifPerOperatorConfig from '@components/ui/DifPerOperatorConfig/DifPerOperatorConfig';
import useSwr from '@hooks/useSwr';
import { getDocumentTitle } from '@src/utils/changeDocumentTitle';
import {
  bytesFormat,
  extractDateInISOFormat,
  formatCurrency,
} from '@src/utils/formatters';
import SessionContext from '@src/store/SessionContext/SessionContext';
import Inventory from '@src/pages/Management/Inventory/Inventory';
import OverflowRangesConfig from '@components/ui/OverflowRangesConfig/OverflowRangesConfig';
import Transfer from '@src/pages/Management/Transfer/Transfer';
import BackToListing from '@src/components/BackToListing/BackToListing';

const { Paragraph } = Typography;

const DetailedRequest = () => {
  const { t } = useTranslation(['requests', 'attributes', 'contracts']);
  const { t: tMainContainer } = useTranslation('main-container');

  const {
    customer: { customerLogged, customerLoggedName },
  } = useContext(SessionContext);
  const navigate = useNavigate();

  const { requestId } = useParams();

  const { data: requestDetails } = useSwr(
    `/service-proxy/broker/virtual-account/${requestId}`,
  );

  const isArchived = requestDetails?.status === 'ARCHIVED';

  const { data: difChargePerOperator } = useSwr(
    '/service-proxy/broker/pricing-plan-operator',
    {
      idVirtualAccount: requestId,
    },
  );

  const { data: overflowRanges } = useSwr(
    '/service-proxy/broker/overflow-ranges',
    {
      idVirtualAccount: requestId,
    },
  );

  const isPayableOrReceivable = useMemo(() => {
    const customerLoggedId = customerLogged?.id;
    const requestCustomerId = requestDetails?.customer?.id;
    if (customerLoggedId && requestCustomerId) {
      if (customerLoggedId === requestCustomerId) {
        return 'pay';
      }
      return 'receive';
    }
  }, [customerLogged?.id, requestDetails?.customer?.id]);

  const contractDetails = useMemo(
    () => requestDetails?.virtualContract,
    [requestDetails],
  );

  const customerDetails = useMemo(
    () => requestDetails?.customer,
    [requestDetails],
  );

  useEffect(() => {
    getDocumentTitle(customerLoggedName, tMainContainer, customerDetails?.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location, customerDetails, customerLoggedName, tMainContainer]);

  const handleActivatedTag = useCallback(
    value => {
      const [color, text] = value
        ? ['green', 'activated']
        : ['red', 'disabled'];
      return (
        <Tag color={color}>
          {t(`attributes:automaticBlocking.${text}`).toUpperCase()}
        </Tag>
      );
    },
    [t],
  );

  const handleHasTag = useCallback(
    value => {
      const [color, text] = value ? ['green', 'yes'] : ['red', 'no'];
      return (
        <Tag color={color}>{t(`attributes:hasTag.${text}`).toUpperCase()}</Tag>
      );
    },
    [t],
  );

  const typeOfPenalty = useMemo(() => {
    const type = t(`attributes:typesOfPenalty.${requestDetails?.loyaltyType}`);
    return t(`attributes:${type}`);
  }, [t, requestDetails]);

  const requestItemsDescriptions = useMemo(
    () => [
      {
        label: t('attributes:description'),
        value: requestDetails?.name,
        showItem: requestDetails?.name,
      },
      {
        label: t('attributes:monthlyPayment'),
        value: formatCurrency(parseFloat(requestDetails?.value)),
        showItem: requestDetails?.value,
      },
      {
        label: t('attributes:mbExcValue'),
        value: formatCurrency(parseFloat(requestDetails?.megabyteExcPrice)),
        showItem:
          requestDetails?.megabyteExcPrice &&
          (requestDetails?.payPerUseFlag === 'N' ||
            contractDetails?.payPerUseFlag === 'N'),
      },
      {
        label: t('attributes:activationPrice'),
        value: formatCurrency(parseFloat(requestDetails?.activationPrice)),
        showItem: requestDetails?.activationPrice,
      },
      {
        label: t('attributes:typesOfPenalty.title'),
        value: typeOfPenalty,
        showItem: true,
      },
      {
        label: t('attributes:penaltyAmount'),
        value: formatCurrency(parseFloat(requestDetails?.penalty)),
        showItem: requestDetails?.penalty && requestDetails?.loyaltyType === 1,
      },
      {
        label: t('attributes:loyaltyTime'),
        value: requestDetails?.loyaltyTime,
        showItem: requestDetails?.loyaltyType === 2,
      },
      {
        label: t('attributes:automaticBlocking.title'),
        value: handleActivatedTag(requestDetails?.automaticBlocking),
        showItem: true,
      },
      {
        label: t('attributes:roaming'),
        value: handleActivatedTag(requestDetails?.roaming),
        showItem: requestDetails?.roaming || requestDetails?.roaming === false,
      },
      {
        label: t('attributes:activationDate'),
        value:
          requestDetails?.activationDate &&
          extractDateInISOFormat(requestDetails?.activationDate),
        showItem: requestDetails?.activationDate,
      },
      {
        label: t('attributes:minimumConsumption'),
        value:
          requestDetails?.preActivationExemptTraffic &&
          bytesFormat(requestDetails?.preActivationExemptTraffic),
        showItem: true,
      },
      {
        label: t('attributes:nbPreActivationDays'),
        value:
          !requestDetails?.nbPreActivationDays ||
          requestDetails?.nbPreActivationDays === 0
            ? t('attributes:noPreActivation')
            : t('attributes:day', {
                count: requestDetails?.nbPreActivationDays,
              }),
        showItem: true,
      },
      {
        label: t('attributes:numberOfTradeInInvoices'),
        value:
          requestDetails?.tradeInMonths ??
          t('modal.configStep.noTradeInInvoices'),
        showItem: requestDetails?.tradeInMonths,
      },
      {
        label: t('attributes:nbSimcards'),
        value: requestDetails?.nbSimcards,
        showItem: requestDetails?.nbSimcards,
      },
      {
        label: t('attributes:blockedByDefault'),
        value: handleActivatedTag(requestDetails?.blockedByDefault),
        showItem: true,
      },
      {
        label: t('attributes:chargeActivationFeeOnTransfer'),
        value: handleHasTag(requestDetails?.chargeActivationFeeOnTransfer),
        showItem: !!requestDetails?.nbPreActivationDays,
      },
      {
        label: t('attributes:trackingNumbers'),
        value: (
          <Paragraph
            copyable={{
              tooltips: [t('copyable.copy'), t('copyable.copied')],
            }}
            style={{ margin: 0, color: 'rgba(0, 0, 0, 0.65)' }}
          >
            {requestDetails?.trackingNumbers?.join(';')}
          </Paragraph>
        ),
        showItem: requestDetails?.trackingNumbers?.length > 0,
      },
    ],
    [
      t,
      requestDetails,
      contractDetails,
      typeOfPenalty,
      handleActivatedTag,
      handleHasTag,
    ],
  );

  const chargeDifPerOperatorFormatted = useMemo(
    () =>
      contractDetails?.operatorDiffChargeFlag === 'S'
        ? t('attributes:chargingType.difPerOp')
        : t('attributes:chargingType.fixed'),
    [contractDetails, t],
  );

  const contractTypeFormatted = useMemo(() => {
    if (contractDetails?.payPerUseFlag === 'S') {
      return t('attributes:type.payPerUse');
    }

    switch (contractDetails?.sharingType) {
      case 1:
        return t('attributes:type.sharedWithout');
      case 2:
        return t('attributes:type.sharedTotal');
      case 3:
        return t('attributes:type.sharedMixed');
      default:
        return '';
    }
  }, [contractDetails, t]);

  const contractDescriptionLink = useMemo(
    () =>
      contractDetails?.id ? (
        <Tooltip title={t('view-contract')}>
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={() =>
              navigate(`/financial/contracts/${contractDetails?.id}`)
            }
          >
            <span style={{ position: 'relative', top: -5 }}>
              {contractDetails?.planDesc}
            </span>
          </Button>
        </Tooltip>
      ) : (
        contractDetails?.planDesc
      ),
    [contractDetails, navigate, t],
  );

  const contractItemsDescriptions = useMemo(
    () => [
      {
        label: t('attributes:description'),
        value: contractDescriptionLink,
        showItem: contractDetails?.planDesc,
      },
      {
        label: t('attributes:externalId'),
        value: contractDetails?.externalId,
        showItem: contractDetails?.externalId,
      },
      {
        label: t('attributes:type.title'),
        value: contractTypeFormatted,
        showItem: contractTypeFormatted,
      },
      {
        label: t('attributes:franchise'),
        value: `${contractDetails?.franchise} MB`,
        showItem: contractDetails?.franchise,
      },
      {
        label: t('attributes:chargingType.title'),
        value: chargeDifPerOperatorFormatted,
        showItem: contractDetails?.payPerUseFlag === 'N',
      },
      {
        label: t('attributes:operator'),
        value: contractDetails?.motOperators?.name,
        showItem: contractDetails?.motOperators?.name,
      },
      {
        label: t('contracts:newContract.planType'),
        value: t(
          `contracts:newContract.planTypes.${requestDetails?.planType?.toLowerCase()}`,
        ),
        showItem: requestDetails?.planType,
      },
      {
        label: t('contracts:newContract.planValidity'),
        value: `${requestDetails?.planValidity} ${t(
          'contracts:newContract.months',
        )}`,
        showItem: requestDetails?.planValidity,
      },
      {
        label: t('contracts:newContract.autoRenewal'),
        value: requestDetails?.automaticRenewal
          ? t('contracts:newContract.yes')
          : t('contracts:newContract.no'),
        showItem: requestDetails?.planType,
      },
      {
        label: t('contracts:newContract.planValidity'),
        value: requestDetails?.planExpirationMonths,
        showItem:
          requestDetails?.planExpirationMonths &&
          requestDetails?.planType === 'ALLOWANCE',
      },
      {
        label: t('attributes:iscaDurationInDays'),
        value: requestDetails?.iscaDurationInDays,
        showItem: requestDetails?.planType === 'BAIT',
      },
      {
        label: t('attributes:iscaMaxRenewal'),
        value: requestDetails?.iscaMaxRenewal,
        showItem: requestDetails?.planType === 'BAIT',
      },
      {
        label: t('attributes:chargeIscaOnTransfer'),
        value: handleHasTag(requestDetails?.chargeIscaOnTransfer),
        showItem:
          requestDetails?.planType === 'BAIT' &&
          !!requestDetails?.nbPreActivationDays,
      },
      {
        label: t('attributes:chargeIscaOnTransfer'),
        value: handleHasTag(requestDetails?.chargeIscaOnTransfer),
        showItem:
          requestDetails?.planType === 'BAIT' &&
          !!requestDetails?.nbPreActivationDays,
      },
    ],
    [
      t,
      contractDescriptionLink,
      contractDetails,
      requestDetails,
      contractTypeFormatted,
      chargeDifPerOperatorFormatted,
      handleHasTag,
    ],
  );

  const clientItemsDescriptions = useMemo(
    () => [
      {
        label: t('attributes:name'),
        value: customerDetails?.name,
      },
      {
        label: t('attributes:nickname'),
        value: customerDetails?.nickname,
      },
      {
        label: t('attributes:cpfCnpj'),
        value: customerDetails?.cpfCnpj,
      },
      {
        label: t('attributes:tradingName'),
        value: customerDetails?.tradingName,
      },
      {
        label: t('attributes:customerType.title'),
        value: t(`attributes:customerType.${customerDetails?.type}`),
      },
    ],
    [t, customerDetails],
  );

  const DividerModel = useCallback(
    ({ title }) => (
      <Divider
        style={{
          marginTop: 24,
          marginBottom: 32,
          fontSize: '0.85rem',
          opacity: 0.7,
          textTransform: 'uppercase',
        }}
        orientation="left"
      >
        {title}
      </Divider>
    ),
    [],
  );

  return (
    <Skeleton loading={!requestDetails} active>
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        <Col lg={24} sm={24} xs={24}>
          <Card title={<BackToListing title={t('requestDetails')} />}>
            <Descriptions
              column={{ lg: 3, md: 2, sm: 2, xs: 1 }}
              style={{ marginTop: 24 }}
            >
              {requestItemsDescriptions?.map(
                ({ label, value, showItem }) =>
                  showItem &&
                  value && (
                    <Descriptions.Item key={label} label={label}>
                      {value}
                    </Descriptions.Item>
                  ),
              )}
            </Descriptions>
            <DividerModel title={t('attributes:contract')} />
            <Descriptions column={{ lg: 3, md: 2, sm: 2, xs: 1 }}>
              {contractItemsDescriptions?.map(
                ({ label, value, showItem }) =>
                  showItem &&
                  value && (
                    <Descriptions.Item key={label} label={label}>
                      {value}
                    </Descriptions.Item>
                  ),
              )}
            </Descriptions>

            <DividerModel title={t('attributes:client')} />
            <Descriptions column={{ lg: 3, md: 2, sm: 2, xs: 1 }}>
              {clientItemsDescriptions?.map(
                ({ label, value }) =>
                  value && (
                    <Descriptions.Item key={label} label={label}>
                      {value}
                    </Descriptions.Item>
                  ),
              )}
            </Descriptions>
          </Card>
        </Col>
      </Row>

      {overflowRanges?.listaFaixaExcedente?.length > 0 &&
        contractDetails?.payPerUseFlag === 'S' && (
          <Card title={t('cards.excessRanges')} style={{ marginTop: 16 }}>
            <Skeleton loading={!overflowRanges} active>
              <OverflowRangesConfig
                overflowRanges={overflowRanges?.listaFaixaExcedente}
                noInteraction
                noTitle
              />
            </Skeleton>
          </Card>
        )}

      {difChargePerOperator?.listaPPOperadora?.length > 0 &&
        contractDetails?.operatorDiffChargeFlag === 'S' && (
          <Card
            title={t('cards.difChargePerOperator')}
            style={{ marginTop: 16 }}
          >
            <Skeleton loading={!difChargePerOperator} active>
              <DifPerOperatorConfig
                difPerOperator={difChargePerOperator?.listaPPOperadora}
                noInteraction
                noTitle
              />
            </Skeleton>
          </Card>
        )}

      {requestDetails?.id && isPayableOrReceivable !== 'pay' && (
        <div style={{ marginTop: 16 }}>
          <Transfer
            isInTheRequestDetails
            defaultFilters={{
              virtualAccountId: requestDetails?.id,
            }}
            columnsToRemove={['oldCustomerName', 'newCustomerName', 'request']}
          />
        </div>
      )}

      {requestDetails?.id && !isArchived && (
        <div style={{ marginTop: 16 }}>
          <Inventory
            isInTheRequestDetails
            defaultFilters={{
              showContracted:
                isPayableOrReceivable === 'pay' ? 'true' : 'false',
              accountId: requestDetails?.id,
            }}
            defaultColumns={[
              'actions',
              'consumption',
              'operator',
              'connOperator',
              'rat',
              'online',
              'blockingType',
              'msisdn',
              'iccid',
              'imsi',
              'imei',
              'lastConnection',
              'status',
              'activationDate',
              'imeiLocked',
              'preActivationEnd',
            ]}
          />
        </div>
      )}
    </Skeleton>
  );
};

export default DetailedRequest;
