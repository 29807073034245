import React, { useEffect, useState, useCallback } from 'react';
import {
  Form,
  Steps,
  Button,
  Tabs,
  Skeleton,
  Typography,
  Divider,
  message,
} from 'antd';
import apiMiddleware from '@services/apiMiddleware';
import { useTranslation } from 'react-i18next';
import CustomerInfoForm from './CustomerInfoForm';
import CustomerAdminForm from './CustomerAdminForm';
import CustomerPermissionsForm from './CustomerPermissionsForm';
import * as Styled from './styles';
import CustomerPersonalization from './CustomerPersonalization/CustomerPersonalization';
import { CheckCircleOutlined } from '@ant-design/icons';
import useSwr from '@src/hooks/useSwr';

const { Step } = Steps;
const { TabPane } = Tabs;
const { Title, Paragraph } = Typography;

const CustomerModal = ({
  customerId,
  visible,
  onClose,
  mutateData,
  newApnData,
}) => {
  const { t } = useTranslation(['customers', 'roles']);

  const { data: customerToBeEdited, mutate: mutateCustomerEdited } = useSwr(
    customerId > 0 ? `/customers/${customerId}` : null,
  );

  const [...forms] = [
    ...Form.useForm(),
    ...Form.useForm(),
    ...Form.useForm(),
    ...Form.useForm(),
  ];

  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [customer, setCustomer] = useState();
  const [idNewCustomer, setIdNewCustomer] = useState();
  const [saveLoading, setSaveLoading] = useState(false);
  const [personalization, setPersonalization] = useState();
  const [isReady, setIsReady] = useState(false);
  const [skipPersonalization, setSkipPersonalization] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [isBroker, setIsBroker] = useState(false);

  const totalStep = isBroker ? 4 : 3;

  const createCustomer = useCallback(
    newCustomer => apiMiddleware.post(`/customers/`, newCustomer),
    [],
  );

  const customerPersonalization = useCallback(
    formData => apiMiddleware.put(`/customers/customizationTheme/`, formData),
    [],
  );

  const updateCustomer = useCallback(
    payload => apiMiddleware.put(`/customers/${customerId}`, payload),
    [customerId],
  );

  useEffect(() => {
    setIsNewCustomer(customerId === -1);
  }, [customerId, visible]);

  const close = useCallback(
    (cancelled = false) => {
      setSaveLoading(false);
      setCurrentStep(0);
      forms.map(f => f.resetFields());
      setCustomer(undefined);
      setPersonalization(undefined);
      setSelectedRoles(undefined);
      setIsBroker(false);
      onClose(cancelled);
      setIsReady(false);
    },
    [forms, onClose],
  );

  const formattedRoles = useCallback(
    () => selectedRoles?.map(roleId => ({ id: roleId })),
    [selectedRoles],
  );

  const onFinish = useCallback(async () => {
    const formateDueDay = dueDay => {
      if (!dueDay) return undefined;
      if (dueDay.length === 2) {
        const first = dueDay[0];
        const second = dueDay[1];
        return {
          dueDay: first > second ? second : first,
          secondDueDay: first > second ? first : second,
        };
      }
      return { dueDay: dueDay[0] };
    };

    if (isNewCustomer) {
      const customerInfo = forms[0].getFieldValue();
      const userAccount = forms[2].getFieldValue();

      const { confirm, password, ...user } = userAccount;
      const newUser = {
        ...user,
        attributes: { ...user.attributes },
        credentials: [{ value: password }],
      };

      newUser.attributes.customerType = customerInfo?.type;

      const apns = {
        apns: customerInfo.apns?.map(apn => ({
          nameApn: apn,
          mainApn: true,
        })),
      };

      const newCustomer = {
        ...customer,
        ...formateDueDay(customerInfo?.dueDay),
        ...apns,
        roles: formattedRoles(),
        user: newUser,
      };

      try {
        const { data } = await createCustomer(newCustomer);
        const { id } = data;
        setIdNewCustomer(id);
        mutateData('CREATE', {
          ...newCustomer,
          id,
        });
        if (!skipPersonalization && isBroker) {
          const formPersonalization = new FormData();
          const { file, mainColor, domain, whatsapp, whatsappMessage } =
            personalization;
          formPersonalization.append('customerId', id);
          formPersonalization.append('file', file);
          formPersonalization.append('mainColor', mainColor);
          formPersonalization.append('domain', domain);
          formPersonalization.append('whatsapp', whatsapp);
          formPersonalization.append('whatsappMessage', whatsappMessage);
          await customerPersonalization(formPersonalization);
        }
        message.success(t('msgs.success-creating-customer'));
        setIsReady(true);
      } catch (error) {
        const status = error?.response?.status;
        if (status === 409) {
          forms[2].setFields([
            {
              name: 'username',
              errors: [t('form.msgs.usernameAlreadyRegistered')],
            },
          ]);
          setCurrentStep(2);

          // domain already exists with logo img
        } else if (status === 416) {
          message.success(t('msgs.success-creating-customer'));
          setIsReady(true);

          // CPF/CNPJ already exists
        } else if (status === 417) {
          forms[0].setFields([
            {
              name: 'cpfCnpj',
              errors: [t('form.msgs.cpfCnpjAlreadyRegistered')],
            },
          ]);
          setCurrentStep(0);
        } else {
          message.error(t('msgs.error-creating-customer'));
        }
      }
      setSaveLoading(false);
    } else {
      forms[0].validateFields().then(async updatedCustomer => {
        try {
          const addressUpdate = updatedCustomer.address;
          const address = {
            id: customerToBeEdited.address.id,
            ...addressUpdate,
          };

          const contactUpdate = updatedCustomer.contact;
          const contact = {
            id: customerToBeEdited.contact.id,
            ...contactUpdate,
          };

          const apns = {
            apns: updatedCustomer.apns?.map(apn => ({
              nameApn: apn,
              mainApn: true,
            })),
          };

          const { parentId, loginId, rootGroupName } = customerToBeEdited;

          const payload = {
            ...updatedCustomer,
            ...formateDueDay(updatedCustomer?.dueDay),
            parentId,
            loginId,
            rootGroupName,
            address,
            contact,
            ...apns,
          };

          await updateCustomer(payload);
          mutateCustomerEdited(payload);
          mutateData('UPDATE', payload);
          close(false);
          message.success(t('msgs.success-updating-customer'));
        } catch {
          message.error(t('msgs.error-updating-customer'));
        }
      });
    }
  }, [
    close,
    personalization,
    createCustomer,
    customer,
    formattedRoles,
    forms,
    isNewCustomer,
    mutateData,
    t,
    updateCustomer,
    customerPersonalization,
    isBroker,
    skipPersonalization,
    customerToBeEdited,
    mutateCustomerEdited,
  ]);

  useEffect(() => {
    if (isReady) {
      setCurrentStep(oldStep => oldStep + 1);
    }
  }, [isReady]);

  const formValidate = useCallback(
    indexForm => {
      const isNotAdminForm = currentStep !== (isBroker ? 3 : 2);

      forms[indexForm].validateFields().then(() => {
        forms[indexForm].submit();
        isNotAdminForm && setCurrentStep(currentStep + 1);
      });
    },
    [forms, currentStep, isBroker],
  );

  // clear permissions error
  useEffect(() => {
    if (selectedRoles?.length > 0) {
      forms[1].setFields([
        {
          name: 'roles',
          errors: [],
        },
      ]);
    }
  }, [selectedRoles, forms]);

  const nextStep = useCallback(() => {
    if (currentStep === 1) {
      if (selectedRoles.length === 0) {
        forms[1].setFields([
          {
            name: 'roles',
            errors: [t('roles:modal.form.msgs.chooseAtLeastOne')],
          },
        ]);
        return;
      } else {
        setCurrentStep(currentStep + 1);
      }
    } else {
      if (currentStep < forms.length) {
        formValidate(currentStep);
      } else {
        setCurrentStep(currentStep + 1);
      }
    }
  }, [currentStep, formValidate, forms, selectedRoles?.length, t]);

  const handleReadyForm = useCallback(() => {
    forms[currentStep].validateFields().then(() => setSaveLoading(true));
  }, [currentStep, forms]);

  useEffect(() => {
    if (saveLoading) {
      onFinish();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveLoading]);

  return (
    <Styled.ModalContainer
      bodyStyle={{ minHeight: 500 }}
      footer={
        <>
          {currentStep < forms?.length &&
            currentStep > 0 &&
            !isReady &&
            !saveLoading && (
              <Button
                onClick={() => {
                  setCurrentStep(currentStep - 1);
                  setSaveLoading(false);
                }}
              >
                {t('buttons.backButton')}
              </Button>
            )}
          {isNewCustomer && (
            <>
              {currentStep < totalStep - 1 ? (
                <Button type="primary" onClick={nextStep}>
                  {t('buttons.nextButton')}
                </Button>
              ) : (
                <>
                  {!isReady && (
                    <Button
                      type="primary"
                      onClick={handleReadyForm}
                      loading={saveLoading}
                    >
                      {t('modal.stepsTitles.ready')}
                    </Button>
                  )}
                </>
              )}
            </>
          )}
          {!isNewCustomer && (
            <Button loading={saveLoading} type="primary" onClick={onFinish}>
              {t('buttons.saveButton')}
            </Button>
          )}
        </>
      }
      maskClosable={false}
      okButtonProps={{ form: 'customerForm', htmlType: 'submit' }}
      title={
        isNewCustomer
          ? t('modal.title.newCustomer')
          : t('modal.title.updateCustomer')
      }
      visible={visible}
      width="70%"
      centered
      forceRender
      onCancel={() => close(true)}
    >
      {isNewCustomer && (
        <>
          {!isReady ? (
            <>
              <Steps current={currentStep} size="small">
                <Step title={t('modal.stepsTitles.information')} />
                <Step title={t('modal.stepsTitles.permissions')} />
                <Step title={t('modal.stepsTitles.admin')} />
                {isBroker && (
                  <Step title={t('modal.stepsTitles.personalization')} />
                )}
              </Steps>

              <Divider />

              <Tabs activeKey={`${currentStep}`} className="modal-tab">
                <TabPane key="0">
                  <CustomerInfoForm
                    customerId={customerId}
                    form={forms[0]}
                    isNewCustomer={isNewCustomer}
                    onFormFinish={setCustomer}
                    onChangeCustomerType={newCustomerType =>
                      setIsBroker(newCustomerType === 'BROKER')
                    }
                    close={close}
                    mutateData={mutateData}
                    newApnData={newApnData}
                  />
                </TabPane>
                <TabPane key="1">
                  <CustomerPermissionsForm
                    form={forms[1]}
                    setSelectedRoles={setSelectedRoles}
                    parentIdSelected={forms[0].getFieldValue('parentId')}
                    customerTypeWillCreated={forms[0].getFieldValue('type')}
                  />
                </TabPane>
                <TabPane key="2">
                  <CustomerAdminForm form={forms[2]} />
                </TabPane>
                {isBroker && (
                  <TabPane key="3">
                    <CustomerPersonalization
                      form={forms[3]}
                      onFormChange={setPersonalization}
                      isSaving={saveLoading}
                      skipPersonalization={() => {
                        setSkipPersonalization(true);
                        setSaveLoading(true);
                      }}
                    />
                  </TabPane>
                )}
              </Tabs>
            </>
          ) : (
            <Skeleton loading={!forms[2]} active>
              <div
                style={{
                  textAlign: 'center',
                  padding: '24px 0',
                  opacity: '.9',
                }}
              >
                <Title>{`${t('modal.ready.title')}!`}</Title>
                <Paragraph
                  style={{
                    maxWidth: '680px',
                    margin: '0 auto',
                    fontSize: '1.3rem',
                    opacity: '.9',
                  }}
                >
                  <p>
                    {t('modal.ready.subTitle')}
                    <strong>{idNewCustomer}</strong>
                    <br />
                    {t('modal.ready.user')}
                    <strong>{forms[2].getFieldValue('username')}</strong>
                  </p>
                  <CheckCircleOutlined
                    size="small"
                    style={{ padding: 40, fontSize: 120, color: 'green' }}
                  />
                </Paragraph>
              </div>
            </Skeleton>
          )}
        </>
      )}
      {!isNewCustomer && (
        <CustomerInfoForm
          customerId={customerId}
          form={forms[0]}
          isNewCustomer={isNewCustomer}
          onFormFinish={setCustomer}
          mutateData={mutateData}
          close={close}
          newApnData={newApnData}
        />
      )}
    </Styled.ModalContainer>
  );
};

export default CustomerModal;
